import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    Grid,
    LinearProgress,
    adaptV4Theme,
    Stack,
    IconButton,
    Box
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Cancelled from '../../res/images/cancelled.png'
import Complete from '../../res/images/completed.png'
import Incomplete from '../../res/images/incomplete.png'
import PendingEpicUpdate from '../../res/images/pending_epic_update.png'
import Closed from '../../res/images/closed.png'
import Controls from '../controls/Controls'
import { useDispatch, useSelector } from 'react-redux';
import { dashboardCaseData, dashboardToggle, getLoading, isDashboardBl } from '../../reducers/DashboardReducer';
import moment from 'moment';
import {
    updateLoaderState,
    toggleToMonthView,
    getDashboardCases,
    getDashboardMonthCases,
    updateDashBoardStartDate,
    updateDashBoardEndDate,
} from '../../actions/DashboardAction'
import MonthCalendarView from "../dashboard/MonthCalendarView";
import Loader from "../common/Loader";
import {
    COMMON_DATE_FORMAT,
    LBL_TIME,
    LBL_PATIENT_NAME,
    LBL_DOB,
    LBL_PROCEDURA_LIST,
    LBL_LOCATION,
    LBL_MEDTEL_CASE_ID,
    LBL_PROCEDURE_UNIT,
    LBL_CONFIRMATION_HASH,
    LBL_STATUS,
    LBL_PROCEDURE_DATE,
    DASHBOARD_DEFAULT_ITEM_PER_PAGE,
    DEFAULT_PER_PAGE_OPTIONS,
    DASHBOARD_DEFAULT_PAGE_NUMBER,
    ERR_INVALID_DATE,
    FROM_DATE_MSG,
    TO_DATE_MSG,
    LBL_DASHBOARD_FROM_DATE,
    LBL_DASHBOARD_TO_DATE,
    LBL_DASHBOARD_SELECT_MONTH,
    DASHBOARD_DATE_FORMAT,
    YEAR_FORMAT,
    DASHBOARD_MONTH_YEAR_FORMAT,
    INVALID_MONTH_YEAR_MSG
} from '../../constants/GlobalConstants';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CaseStatusSnapshot from '../dashboard/CaseStatusSnapshot';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DashboardTable from './DashboardTable';
import { useHistory } from "react-router-dom";

const MappingKeys = {
    PatientName: "PatientFullName",
    DocumentCount: "DocumentCount",
    PatientDOB: "PatientDOB",
    PrimarySurgeonName: "PrimarySurgeonName",
    HospitalMRN: "HospitalMRN",
    CaseId: "CaseId",
    ProcedureUnitName: "ProcedureUnitName",
    EncounterId: "EncounterId",
    ConfirmationNumber: "ConfirmationNumber",
    ServiceLineName: "ServiceLineName",
    CaseStatusId: "CaseStatusId",
    SurgicalLocationName: "SurgicalLocationName",
    BookingSheetId: "BookingSheetId",
    BookingSheetName: "BookingSheetName",
    ProcedureDate: "ProcedureDate",
    ProcedureTime: "ProcedureTime",
    ApproverStatusId: "ApproverStatusId",
    CaseRuleResultsBl: "CaseRuleResultsBl",
    open: "open",
    caseRules: "caseRules",
    nonCompliantGuidelinesLoading: "nonCompliantGuidelinesLoading",
    fieldPermissions: "fieldPermissions",
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fafafc',
        margin: `0px ${theme.spacing(2)}`,

    },
    toggleSection: {
        //margin: `0px ${theme.spacing(2)}`,
    },

    searchSection: {
        margin: `0px 0px 0px 70%`
    },

    viewSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: "left"
    },
    marginOverall: {
        margin: ".5% ",
    },


    dayViewColor: {
        //margin: `0px ${theme.spacing(2)}`,
        color: '#2b94c9',
    },

    caseStatusIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        verticalAlign: 'middle'
    },
    DataTableWraper: {
        display: 'flex',
        flexDirection: 'column',

    },
    caseStatusSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    button: {
        textTransform: 'capitalize',
        height: 40,
        boxShadow: 'none',
        borderRadius: 8,
    },
}))

const getMuiTheme = () => createTheme(adaptV4Theme({
    overrides: {
        MUIDataTable: {
            root: {
                maxWidth: "100%",
                flexBasis: "100%"
            },
            paper: {
                boxShadow: "none"
            },
        },
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#FFF'
                }
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: "#2b94c9 !important",
                color: "#FFF !important",
                fontSize: '0.875rem',
                '&:MUIDataTableHeadCell-sortActive': {
                    color: 'white'
                }
            },
            body: {
                fontSize: '0.875rem'
            }
        },

    }
}))

function DataTable() {
    const classes = useStyles();
    const tableBodyHeight = 'auto'
    const history = useHistory();
    const dispatch = useDispatch()
    const monthToggle = useSelector(dashboardToggle)
    var loading = useSelector(getLoading);
    const dashboardCases = useSelector(dashboardCaseData)
    const dahsboardSearchBl = useSelector(isDashboardBl)

    const [fromDate, setFromDate] = useState(moment())
    const [toDate, setToDate] = useState(moment())
    const [month, setMonth] = useState(moment())
    const [startOfMonth, setStartOfMonth] = useState(moment().startOf('month').format(DASHBOARD_DATE_FORMAT))
    const [endOfMonth, setEndOfMonth] = useState(moment().endOf('month').format(DASHBOARD_DATE_FORMAT))
    const [maxFromDate, setMaxFromDate] = useState(new Date(toDate))
    const [minFromDate, setMinFromDate] = useState(new Date(moment(toDate).subtract(1, 'years')))
    const [maxToDate, setMaxToDate] = useState(new Date(moment(fromDate).add(1, 'years')))
    const [minToDate, setMinToDate] = useState(new Date(fromDate))
    const [fromDateHelperText, setFromDateHelperText] = useState('')
    const [toDateHelperText, setToDateHelperText] = useState('')
    const [monthHelperText, setMonthHelperText] = useState('')
    const [disableFilter, setDisableFilter] = useState(false)
    const dashboardStartDate = sessionStorage.getItem("dashboardStartDate")
    const dashboardEndDate = sessionStorage.getItem("dashboardEndDate")

    useEffect(() => {
        if (dashboardStartDate) {
            const startDate = dashboardStartDate == "null" ? null : dashboardStartDate
            setFromDate(startDate)
            handleMaxToDate(startDate)
        }
        if (dashboardEndDate) {
            const endDate = dashboardEndDate == "null" ? null : dashboardEndDate
            setToDate(endDate)
            handleMaxFromDate(endDate)
        }
    }, [])

    useEffect(() => {
        if (!monthToggle) {
            const yearFromDate = moment(fromDate).year();
            const isFromValidYear = moment(yearFromDate, YEAR_FORMAT, true).isValid()
            const yearToDate = moment(toDate).year();
            const isToValidYear = moment(yearToDate, YEAR_FORMAT, true).isValid()

            if (fromDate && (!moment(fromDate , true).isValid() || !isFromValidYear)) {
                setFromDate(null);
                setFromDateHelperText('');
                handleMaxToDate(null);
            }
            if (toDate && (!moment(toDate ,true).isValid() || !isToValidYear)) {
                setToDate(null);
                setToDateHelperText('');
                handleMaxFromDate(null);
            }
        if (
                (fromDate && moment(fromDate, true).isValid() && isFromValidYear) ||
                (toDate && moment(toDate, true).isValid() && isToValidYear)
            ) {
                setDisableFilter(false); 
            } else {
                setDisableFilter(true); 
            }
        } else { 
            const isValidMonth = moment(month, DASHBOARD_MONTH_YEAR_FORMAT, true).isValid();
            if(month && isValidMonth) {
                const yearOfMonth = moment(month).year()
                const isValidYear = moment(yearOfMonth, YEAR_FORMAT, true).isValid()
                
                if(isValidMonth && isValidYear) {
                    setMonthHelperText('')
                    setDisableFilter(false)
                } else {
                    setMonthHelperText(INVALID_MONTH_YEAR_MSG)
                    setDisableFilter(true)
                }
            } else {
                setMonthHelperText('')
                setDisableFilter(true)
            }
        }
    }, [monthToggle]);

    const handleChange = (e) => {
        dispatch(updateLoaderState(e.target.checked))
        dispatch(toggleToMonthView(e.target.checked))
    };

    //To validate dashboard date range
    const validateDateRange = (fromDateValue, toDateValue) => {
        const fromValue = moment(fromDateValue)
        const toValue = moment(toDateValue)
        const fromYear = moment(fromDateValue).year()
        const toYear = moment(toDateValue).year()
        const validFromYear = moment(fromYear, "YYYY", true).isValid()
        const validToYear = moment(toYear, "YYYY", true).isValid()
        const validFromDate = moment(fromValue, COMMON_DATE_FORMAT, true).isValid() && validFromYear
        const validToDate = moment(toValue, COMMON_DATE_FORMAT, true).isValid() && validToYear

        const formDateAfter = moment(fromValue).add(1, 'year')
        const toDateBefore = moment(toValue).subtract(1, 'year')

        let fromDateHelperText = ""
        let toDateHelperText = ""
        let disableButton = false

        if (fromDateValue == null) {
            fromDateHelperText = ""
        } else if (validFromDate && validToDate) {
            if (moment(fromValue).isSameOrBefore(toValue, 'day') && moment(fromValue).isSameOrAfter(toDateBefore, 'day')) {
                fromDateHelperText = ""
            } else {
                fromDateHelperText = FROM_DATE_MSG
                disableButton = true
            }
        } else if (!validFromDate) {
            fromDateHelperText = ERR_INVALID_DATE
            disableButton = true
        }

        if (toDateValue == null) {
            toDateHelperText = ""
        } else if (validToDate && validFromDate) {
            if (moment(toValue).isSameOrAfter(fromValue, 'day') && moment(toValue).isSameOrBefore(formDateAfter, 'day')) {
                toDateHelperText = ""
            } else {
                toDateHelperText = TO_DATE_MSG
                disableButton = true
            }
        } else if (!validToDate) {
            toDateHelperText = ERR_INVALID_DATE
            disableButton = true
        }

        if (toDateValue == null && fromDateValue == null) {
            disableButton = true
        }

        setToDateHelperText(toDateHelperText)
        setFromDateHelperText(fromDateHelperText)
        setDisableFilter(disableButton)
    }

    //To update max date in datepicker according from-date
    const handleMaxToDate = (value) => {
        if (value == null) {
            setMaxToDate(undefined)
            setMinToDate(undefined)
        } else {
            const maxToDate = moment(value).add(1, 'year')
            setMaxToDate(new Date(maxToDate))
            setMinToDate(new Date(value))
        }
    }

    //To update max date in datepicker according to-date
    const handleMaxFromDate = (value) => {
        if (value == null) {
            setMaxFromDate(undefined)
            setMinFromDate(undefined)
        } else {
            const minFromDate = moment(value).subtract(1, 'year')
            setMaxFromDate(new Date(value))
            setMinFromDate(new Date(minFromDate))
        }
    }

    //To update from-date value in state
    const handleFromDate = (e) => {
        setFromDate(e)
        handleMaxToDate(e)
        validateDateRange(e, toDate)
        dispatch(updateDashBoardStartDate(e))
    }

    //To update to-date value in state
    const handleToDate = (e) => {
        setToDate(e)
        handleMaxFromDate(e)
        validateDateRange(fromDate, e)
        dispatch(updateDashBoardEndDate(e))
    }

    //To update month selection
    const handleMonthSelection = (e) => {
        const yearOfMonth = moment(e).year()
        const isValidYear = moment(yearOfMonth, YEAR_FORMAT, true).isValid()
        const isValidMonth = moment(e, DASHBOARD_MONTH_YEAR_FORMAT, true).isValid();
        setMonth(e)

        if(isValidMonth && isValidYear) {
            setMonthHelperText('')
            setDisableFilter(false)
            sessionStorage.setItem("selectedMonth", e)
        } else {
            setMonthHelperText(INVALID_MONTH_YEAR_MSG)
            setDisableFilter(true)
        }
    }

    //Set grid/datatable columns
    const columns = [
        {
        id: MappingKeys.CaseStatusId,
        name: LBL_STATUS,
        options: {
            filter: false,
            display: true,

        }
    },
    {
        id: MappingKeys.ProcedureTime,
        name: LBL_TIME,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.PatientName,
        name: LBL_PATIENT_NAME,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.PatientDOB,
        name: LBL_DOB,
        options: {
            filter: true,
            display: true,
            customBodyRender: value =>
                moment(new Date(value)).format(COMMON_DATE_FORMAT)
        }
    },
    {
        id: MappingKeys.PrimarySurgeonName,
        name: LBL_PROCEDURA_LIST,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.ProcedureDate,
        name: LBL_PROCEDURE_DATE,
        options: {
            filter: true,
            sort: true,
            sortDirection: 'desc',
            customBodyRender: value =>
                moment(new Date(value)).format(COMMON_DATE_FORMAT)
        }
    },
    {
        id: MappingKeys.SurgicalLocationName,
        name: LBL_LOCATION,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.CaseId,
        name: LBL_MEDTEL_CASE_ID,
        options: {
            filter: true,
            sort: true
        }
    },
    {
        id: MappingKeys.ProcedureUnitName,
        name: LBL_PROCEDURE_UNIT,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.ConfirmationNumber,
        name: dashboardCases?.Headers?.find(item => item.FieldMappingId === MappingKeys.ConfirmationNumber)?.FieldName || LBL_CONFIRMATION_HASH,
        options: {
            filter: false,
            display: true,
        }
    },
    {
        id: MappingKeys.DocumentCount,
        name: <AttachFileIcon fontSize="small" />,
        options: {
            filter: false,
            display: true,
        }
    }]

    const options = {
        filter: false,
        filterType: "textField",
        tableBodyHeight,
        search: false,
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        sort: true,
        rowsPerPage: DASHBOARD_DEFAULT_ITEM_PER_PAGE,
        rowsPerPageOptions: DEFAULT_PER_PAGE_OPTIONS,

        setRowProps: row => {

            return {
                style: { cursor: "pointer" }
            };
        },
        expandableRows: false,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;

        },

        onRowClick: (rowData, rowMeta) => {
            let caseId = dashboardCases.Items[rowMeta.dataIndex].Case.CaseId;
            history.push(`case/${parseInt(caseId)}/PatientInformation`);

        },
        textLabels: {
            body: {
                noMatch: "Sorry, there is no matching data to display"
            }
        },
    }

    const checkCaseStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return Closed;
                break;
            case 2:
                return Cancelled;
                break;
            case 3:
                return Complete;
                break;
            case 4:
                return Incomplete;
                break;
            case 9:
                return PendingEpicUpdate;
                break;

        }
    }

    //To get case data in dashboard by start-date/end-date
    const getCaseByDate = () => {
        const selected_start_date = fromDate == null ? moment(toDate).subtract(1, 'year').format(DASHBOARD_DATE_FORMAT) : moment(fromDate).format(DASHBOARD_DATE_FORMAT);
        const selected_end_date = toDate == null ? moment(fromDate).add(1, 'year').format(DASHBOARD_DATE_FORMAT) : moment(toDate).format(DASHBOARD_DATE_FORMAT);

        if (monthToggle) {
            const monthStart = moment(month).startOf('month').format(DASHBOARD_DATE_FORMAT)
            const monthEnd = moment(month).endOf('month').format(DASHBOARD_DATE_FORMAT)
            setStartOfMonth(monthStart)
            setEndOfMonth(monthEnd)
            dispatch(updateLoaderState(true));
            dispatch(getDashboardMonthCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, monthStart, monthEnd));
        } else {
            const dashboardStartDt = fromDate == null ? null : moment(fromDate).format(DASHBOARD_DATE_FORMAT)
            const dashboardEndDt = toDate == null ? null : moment(toDate).format(DASHBOARD_DATE_FORMAT)
            sessionStorage.setItem("dashboardStartDate", dashboardStartDt)
            sessionStorage.setItem("dashboardEndDate", dashboardEndDt)

            dispatch(getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selected_start_date, selected_end_date))
        }

    }

    const CasesResults = [];
    if (dashboardCases && dashboardCases.Items) {
        //  var sortedByCaseId = dashboardCases.Items.sort((a, b) => a.Case.CaseId - b.Case.CaseId);
        // var sortedByProcedureDateTime = sortedByCaseId.sort((a, b) => moment(b.Case.ProcedureDateTime).format('MM/DD/YYYY') - moment(a.Case.ProcedureDateTime).format('MM/DD/YYYY'));
        dashboardCases.Items.forEach(element => {
            var caseListData = []
            caseListData.push(<img src={checkCaseStatus(element.Case.CaseStatusId)} className={classes.caseStatusIcon} alt="" />)
            caseListData.push(element.Case.ProcedureTime)
            caseListData.push(element.Patient.PatientFullName)
            caseListData.push(moment(element.Patient.PatientDOB).toDate().getTime())
            caseListData.push(element.Case.PrimarySurgeonName)
            caseListData.push(moment(element.Case.ProcedureDate).toDate().getTime())
            caseListData.push(element.Case.SurgicalLocationName)
            caseListData.push(parseInt(element.Case.CaseId))
            caseListData.push(element.Case.ProcedureUnitName)
            caseListData.push(element.Case.ConfirmationNumber)
            caseListData.push(element.Document.DocumentCount)
            CasesResults.push(caseListData);
        });

        //Hide column from grid if does not come according permission in header response
        columns.forEach((col) => {
            if (!dashboardCases.Headers.some(item => item.FieldMappingId === col.id)) {
                col.options.display = false;
            }
        })
    }

    const tableData = []
    if (dashboardCases && dashboardCases.Items) {
        dashboardCases.Items.forEach(element => {
            const rowInfo = {}
            rowInfo[MappingKeys.CaseStatusId] = element.Case.CaseStatusId
            rowInfo[MappingKeys.ProcedureTime] = element.Case.ProcedureTime
            rowInfo[MappingKeys.PatientName] = element.Patient.PatientFullName
            rowInfo[MappingKeys.PatientDOB] = moment(element.Patient.PatientDOB).format(COMMON_DATE_FORMAT)
            rowInfo[MappingKeys.PrimarySurgeonName] = element.Case.PrimarySurgeonName
            rowInfo[MappingKeys.ProcedureDate] = element.Case.ProcedureDate
            rowInfo[MappingKeys.SurgicalLocationName] = element.Case.SurgicalLocationName
            rowInfo[MappingKeys.CaseId] = parseInt(element.Case.CaseId)
            rowInfo[MappingKeys.ProcedureUnitName] = element.Case.ProcedureUnitName
            rowInfo[MappingKeys.ConfirmationNumber] = element.Case.ConfirmationNumber
            rowInfo[MappingKeys.DocumentCount] = element.Document.DocumentCount
            rowInfo[MappingKeys.CaseRuleResultsBl] = element.Case.CaseRuleResultsBl
            rowInfo[MappingKeys.ApproverStatusId] = element.Case.ApproverStatusId
            rowInfo[MappingKeys.open] = element.Case.open;
            rowInfo[MappingKeys.caseRules] = element.Case.caseRules;
            rowInfo[MappingKeys.nonCompliantGuidelinesLoading] = element.Case.nonCompliantGuidelinesLoading;
            rowInfo[MappingKeys.fieldPermissions] = element.Case.fieldPermissions;
            tableData.push(rowInfo)
        })
    }
    
    if (dahsboardSearchBl) {
        return (
            <React.Fragment>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getMuiTheme()}>
                        {loading ? <Loader loading={loading} /> :
                            <MUIDataTable
                                data={CasesResults}
                                columns={columns}
                                options={options}
                                className={classes.root}
                            />

                        }

                    </ThemeProvider>
                </StyledEngineProvider>
            </React.Fragment>
        );
    }
    else {

        {
            return (
                <React.Fragment>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getMuiTheme()}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Stack direction="row" spacing={1} display="flex" justifyContent="flex-start" alignItems="flex-start">
                                        {monthToggle ? (
                                            <>
                                                <Box sx={{ maxWidth: '80%' }}>
                                                    <Controls.DatePicker
                                                        label={LBL_DASHBOARD_SELECT_MONTH}
                                                        fieldName={LBL_DASHBOARD_SELECT_MONTH}
                                                        additionalProperties={{ Format: COMMON_DATE_FORMAT }}
                                                        fieldSize="small"
                                                        onChange={handleMonthSelection}
                                                        value={month}
                                                        views={['year', 'month']}
                                                        //maxDate={new Date(moment().add(1, 'years'))}
                                                        //minDate={new Date(moment().subtract(1, 'years'))}
                                                        error={monthHelperText}
                                                        helperText={monthHelperText}
                                                        placeholder="Month YYYY"
                                                        readOnly={true}
                                                    />
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Controls.DatePicker
                                                    label={LBL_DASHBOARD_FROM_DATE}
                                                    fieldName={LBL_DASHBOARD_FROM_DATE}
                                                    additionalProperties={{ Format: COMMON_DATE_FORMAT }}
                                                    fieldSize="small"
                                                    onChange={(e) => handleFromDate(e)}
                                                    value={fromDate}
                                                    maxDate={maxFromDate}
                                                    minDate={minFromDate}
                                                    error={fromDateHelperText}
                                                    helperText={fromDateHelperText}
                                                />
                                                <Controls.DatePicker
                                                    label={LBL_DASHBOARD_TO_DATE}
                                                    fieldName={LBL_DASHBOARD_TO_DATE}
                                                    additionalProperties={{ Format: COMMON_DATE_FORMAT }}
                                                    fieldSize="small"
                                                    onChange={(e) => handleToDate(e)}
                                                    value={toDate}
                                                    maxDate={maxToDate}
                                                    minDate={minToDate}
                                                    error={toDateHelperText}
                                                    helperText={toDateHelperText}
                                                />
                                            </>
                                        )}
                                        <IconButton disabled={disableFilter} color="primary" aria-label="Filter By Date" onClick={() => getCaseByDate()}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
                                        <label className={classes.dayViewColor}>Day View</label>
                                        <Controls.Toggle handleChange={handleChange} checked={monthToggle} />
                                        <label className={classes.toggleSection}>Month View</label>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4} className={classes.caseStatusSection}>
                                    <CaseStatusSnapshot />
                                </Grid>
                                <Grid item xs={12}>
                                    {monthToggle
                                        ?
                                        <div>
                                            {loading ? <LinearProgress /> : null}
                                            <MonthCalendarView startOfMonth={startOfMonth} endOfMonth={endOfMonth} />
                                        </div>
                                        : <div >
                                            {loading ? <LinearProgress /> : null}
                                            <DashboardTable
                                                data={tableData}
                                                checkCaseStatus={checkCaseStatus}
                                                dashboardCases={dashboardCases}
                                            />
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </React.Fragment>
            );
        }


    }
}


export default DataTable;