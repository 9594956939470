import React from 'react';
import { Paper, Typography, Grid, Stack } from '@mui/material';
import Draggable from 'react-draggable';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RuleResultTable from './RuleResultTable'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    position: 'fixed',
    right: '5%',
    left: '10%',
    top: '15%',
    transform: 'translate(-50%, -50%)',
    padding: 20,
    zIndex: 1200,
    boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
  }
}))
 
const DraggableModal = ({ open, onClose }) => {
  if (!open) return null;
  const classes = useStyles()
 
  return (
    <Draggable handle=".modal-header">
      <Paper className={classes.paperContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="modal-header" style={{ cursor: 'move' }}>
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h6">Guidelines</Typography>
                <CloseOutlinedIcon
                  onClick={onClose}
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                />
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12}>
            <RuleResultTable/>
          </Grid>
        </Grid>
      </Paper>
    </Draggable>
  );
};
 
const RuleDialogBox = (props) => {
  const { open, handleClose } = props;
 
  return (
    <div>
      <DraggableModal open={open} onClose={handleClose} />
    </div>
  );
};
 
export default RuleDialogBox;