import React, { useEffect } from 'react'
import { Paper, Card, Typography, LinearProgress, Stack, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Cancelled from '../../res/images/cancelled.png'
import Complete from '../../res/images/completed.png'
import Incomplete from '../../res/images/incomplete.png'
import classNames from 'classnames'
import Alert from '../../res/images/pending_epic_update.png'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Closed from '../../res/images/closed.png'
import { connect, useSelector } from 'react-redux';
import { dashboardCaseData, startDate, endDate, getLoading, dashboardToggle, dashboardMonthCaseData } from '../../reducers/DashboardReducer';
import { getDashboardCases } from '../../actions/DashboardAction';
import * as CaseStatuses from '../../constants/CaseStatuses'
import {
    RULE_PENDING_TITLE,
    RULE_APPROVED_TITLE,
    RULE_REJECTED_TITLE
} from '../../constants/GlobalConstants';
import Pending from '../../res/images/pending.png';
import Approved from '../../res/images/approved.png'
import Rejected from '../../res/images/rejected.png'


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '0.875rem'
    },
    statusSnapshot: {
        position: 'relative',
        margin: 'auto',
        width: 250,
        whiteSpace: 'nowrap',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    filterCardIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        verticalAlign: 'middle',
    }
}))

function CaseStatusSnapshot({ dashboardCases, dashboardToggle, monthView, getDashboardCases, startDate, endDate }) {
    const classes = useStyles();

    var allCaseCounts = dashboardToggle ? monthView : dashboardCases

    var totalCase = allCaseCounts && allCaseCounts.TotalRecords ? allCaseCounts.TotalRecords : 0;
    var completeCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Complete : 0;
    var incompleteCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Incomplete : 0;
    var cancelledCase = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.Cancelled : 0;
    var alert = allCaseCounts && allCaseCounts.CaseStatusesCount != null && allCaseCounts.CaseStatusesCount.Alert ? allCaseCounts.CaseStatusesCount.Alert : 0;
    var pending_review = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.PENDING_REVIEW : 0;
    var approved = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.APPROVED : 0;
    var rejected = allCaseCounts && allCaseCounts.CaseStatusesCount != null ? allCaseCounts.CaseStatusesCount.REJECTED : 0;
    var loading = useSelector(getLoading)
    const headerList = [
        {
            'title': 'All Cases',
            'count': totalCase,
            'caseStatusId': CaseStatuses.ALL_CASES,
        },
        {
            'title': 'Complete',
            'icon': Complete,
            'count': completeCase,
            'caseStatusId': CaseStatuses.COMPLETE,
        },
        {
            'title': 'Incomplete',
            'icon': Incomplete,
            'count': incompleteCase,
            'caseStatusId': CaseStatuses.INCOMPLETE,
        },
        {
            'title': 'Cancelled',
            'icon': Cancelled,
            'count': cancelledCase,
            'caseStatusId': CaseStatuses.CANCELLED,
        },
        {
            'title': 'Alert',
            'icon': Alert,
            'count': alert,
            'caseStatusId': CaseStatuses.ALERT,
        },
         {
            'title': RULE_PENDING_TITLE,
            'icon': Pending,
            'count': pending_review,
            'caseStatusId': CaseStatuses.PENDING_REVIEW,
        },
        {
            'title': RULE_APPROVED_TITLE,
            'icon': Approved,
            'count': approved,
            'caseStatusId': CaseStatuses.APPROVED,
        },
        {
            'title': RULE_REJECTED_TITLE,
            'icon': Rejected,
            'count': rejected,
            'caseStatusId': CaseStatuses.REJECTED,
        }
    ]

    //To remove rule status if count is zero
    const headerListWithCount = headerList.filter(item => {
        const itemListForCountCheck = [RULE_PENDING_TITLE, RULE_APPROVED_TITLE, RULE_REJECTED_TITLE]

        if(itemListForCountCheck.includes(item.title) && (item.count == undefined || item.count == 0)){
            return false
        }

        return true
    })

    return (
        <Paper elevation={0} square className={classes.root}>
            <Stack direction="row" spacing={3}>
                {headerListWithCount.map((filterTiles) => {
                    return (filterTiles.icon !== undefined) ? (
                        <Tooltip title={filterTiles.title} placement="top" arrow>
                            <Stack direction="row" spacing={1}>
                                <img src={filterTiles.icon} alt="" className={classes.filterCardIcon} />
                                <Typography>{filterTiles.count}</Typography>
                            </Stack>
                        </Tooltip>
                    )
                        : <div className={classes.filterCardIcon} />
                })}
            </Stack>
        </Paper >
    )
}


const mapStateToProps = state => ({
    dashboardCases: dashboardCaseData(state),
    dashboardToggle: dashboardToggle(state),
    monthView: dashboardMonthCaseData(state),
    startDate: startDate(state),
    endDate: endDate(state)
});
export default connect(mapStateToProps, { getDashboardCases })(CaseStatusSnapshot);
