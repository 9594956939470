import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { RULE_COMMENT_CHAR_LIMIT } from '../../constants/GlobalConstants'

export default function ApproveRejectDialog(props) {
  const [input, setInput] = React.useState("")
  const { open, onConfirm, onCancel, dialogTitle, confirmTitle } = props;

  React.useEffect(() => {
    setInput("")
  }, [open])

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onCancel}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography>{dialogTitle}</Typography>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={input}
            onChange={(e) => setInput(e.target.value)}
            variant="outlined"
            fullWidth
            margin="dense"
            autoFocus
            label="Comment"
            type="text"
            name="comment"
            inputProps={{
              maxLength: RULE_COMMENT_CHAR_LIMIT,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => onConfirm(input)}>
            {confirmTitle}
          </Button>
          <Button onClick={onCancel} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

