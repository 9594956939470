import { getFieldById } from '../../GetFieldById'
import {
    POSITION_BED_TYPE_MAPPING_ID,
    POSITION_BED_TYPE_BTN_MAPPING_ID,
    DELETE_POSITION_AND_BED_TYPE,
    ON_ADD_EXECUTE_RULES
} from '../../../constants/GlobalConstants';
import RuleFactory from './RuleFactory'

const execute = (control, AddOnLoadONChangeFlag = null) => {
    const fieldProps = control.additionalProperties
    const positionAndbedTypeTableInfo = getFieldById(POSITION_BED_TYPE_MAPPING_ID);
    const childRowCount = positionAndbedTypeTableInfo.childControls.length
    if (fieldProps.DefaultRowCount > 0 && childRowCount == 1) {
        var field = getFieldById(POSITION_BED_TYPE_BTN_MAPPING_ID);
        var rule = RuleFactory.getApplicableRule(ON_ADD_EXECUTE_RULES)
        if (rule) {
            rule.execute(field, true)
        }
    } else if (control.parentMappingId == DELETE_POSITION_AND_BED_TYPE || childRowCount == 2) {
        const additionalProperties = positionAndbedTypeTableInfo.additionalProperties
        if (positionAndbedTypeTableInfo.childControls.length == 2) {
            positionAndbedTypeTableInfo.childControls.forEach((item, idx) => {
                item.forEach(val => {
                    if (val.mappingId.slice(0, -1) == DELETE_POSITION_AND_BED_TYPE && idx != 0) {
                        val.permissionTypeId = additionalProperties.AlterFieldPermissionDueToRowCountInTargetField.IfTrueSetPermissionTypeId
                        val.isVisible = false
                    }
                })
            })
        }
    }
}

const AlterFieldPermissionDueToRowCountInTargetField = {
    execute
}

export default AlterFieldPermissionDueToRowCountInTargetField