import {
  INPUT_REQUIRED_DUE_TO_DEPENDENT_FIELD_VALUE
} from '../../../constants/GlobalConstants';
import { getFieldValueById } from '../../GetFieldValueByID';
import { updateIsRequiredValidation } from '../../../actions/CaseDetailsAction';
import { store } from '../../../store';

export const InputSelectionMoreThanProvidedValueDueToDependentFieldValueInMultipleDataControl = (control) => {
  var fieldValue = control?.value?? null;
  if (control && control.validation) {
    var validation = control.validation
    var dependentFields = validation[INPUT_REQUIRED_DUE_TO_DEPENDENT_FIELD_VALUE] ?? null;
    var response = null
    if (dependentFields != null) {
      dependentFields.forEach(dependentField => {
        var providedValue = dependentField?.ProvidedValue ?? null;
        var dependentFieldId = dependentField?.DependentFieldId ?? null;
        var dependentFieldValue = dependentField?.DependentFieldValue?? null;
        var dependentFieldItems = dependentFieldId?.split(".");
        dependentFieldId = dependentFieldItems?.[0];
        var dependentFieldItemsValueKey = dependentFieldItems[1]
        var fieldValues = getFieldValueById(dependentFieldId);
        for (let i = 0; i < fieldValues?.length; i++) {
          const itemValue = fieldValues[i]?.[dependentFieldItemsValueKey];
          const isMatch = dependentFieldValue?.includes(`${itemValue}`);
          const isFieldEmptyOrMatches = fieldValue == null || fieldValue === providedValue;
        
          if (isMatch && isFieldEmptyOrMatches) {
            response = { isRequired: true, status: false };
            break; 
          }
        }
      });
    }
  }
  if (!response?.isRequired) {
    store.dispatch(updateIsRequiredValidation(control.mappingId, false))
  } else {
    store.dispatch(updateIsRequiredValidation(control.mappingId, true))
  }
  return response;
}