import React from 'react'
import { Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReportsRightSection from './ReportsRightSection';
import ReportsLeftNav from './ReportsLeftNav';
import { useSelector, useDispatch } from 'react-redux';
import { getSurgicalLocationSelections } from '../../reducers/DashboardReducer';
import { getWorkQueueListInfo } from '../../reducers/ReportsReducer';
import { getWorkQueueListDetails } from '../../actions/ReportsAction'

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(12),
        background: '#fff',
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(15)})`,
    }
}))

const Reports = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const surgicalLocation = useSelector(getSurgicalLocationSelections);
    const workQueueList = useSelector(getWorkQueueListInfo);

    React.useEffect(() => {
        if(workQueueList.length == 0){
            dispatch(getWorkQueueListDetails())
        }
    }, [])

    return (
        <Box className={classes.root}>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} padding='0 !important'>
                    {workQueueList.length > 0 && <ReportsLeftNav workQueueList={workQueueList} />}
                </Grid>
                <Grid item xs={12}>
                    <ReportsRightSection />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Reports
