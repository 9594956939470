import React, { useEffect, useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { yellow } from '@mui/material/colors';
import Dashboard from '../../res/images/dashboard.png'
import AddCase from '../../res/images/add_new.png'
import Notification from '../../res/images/notification.png'
import Report from '../../res/images/report.png'
import Setting from '../../res/images/setting.png'
import * as Colors from '../../res/values/Colors'
import { Badge, Tooltip } from '@mui/material';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { isCreateCaseBl, getCreateCaseSection, updateCaseEditableMode } from '../../actions/CaseDetailsAction';
import { dashboardSearchBl, clearDashboardData, getDashboardCases } from '../../actions/DashboardAction';
import SectionAlertAction from '../../actions/SectionAlertAction';
import {
  NAVIGATION_WARNING_MESSAGE,
  DASHBOARD_DEFAULT_PAGE_NUMBER,
  DASHBOARD_DEFAULT_ITEM_PER_PAGE,
  DASHBOARD_DATE_FORMAT
} from '../../constants/GlobalConstants';
import CaseDialogBox from '../case/CaseDialogBox';
import moment from 'moment';
import { UpdateFieldSorting, setSearchFiltersData, resetPaginationState } from '../../actions/ReportsAction';
import EmailOutlinedIcon from '../../res/images/emailOutlined.png'
import { getDashboardFilterDateInfo } from '../dashboard/Dashboard'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',

  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClosed: {
    backgroundImage: `linear-gradient(${Colors.DRAWER_DARK}, ${Colors.DRAWER_REGULAR})`,
    overflowX: 'hidden',
    marginTop: theme.spacing(8),
  },
  sidebarIcon: {
    width: '38px'//theme.spacing(4),
    //height: theme.spacing(4),
  },
  MuiListItemIcon: {
    minWidth: 'unset !important',
    padding: `${theme.spacing(1)} 0px`
  },
  setting: {
    bottom: 0,
    position: 'fixed',
    width: 'auto'
  },

}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#2b94c9",
    color: theme.palette.common.white,
    marginLeft: '-10px',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

function mapStateToProps(state) {
  return {
    dashboardFilterData: state.dashboard.dashboardFiltersData,
    loadedSectionId: state.caseDetails.loadedSectionId,
    caseDetails: state.caseDetails,
  }
}

function SideNavbar(props) {
  const classes = useStyles()
  const dispatch = useDispatch();
  const notification = useSelector(
    state => state.notification
  )
  const history = useHistory();

  const handleNavClick = (component) => {
    if (props.caseDetails.isCaseEditable) {
      document.body.style.overflow = "hidden";
      askForConfirmation(component);
      document.body.style.overflow = "auto";
    }
    else {
      SideNavbarNavigation(component);
      document.body.style.overflow = "auto";
    }
  }

  const SideNavbarNavigation = (component) => {

    dispatch(dashboardSearchBl(false))
    switch (component) {
      case 'add_case':
        dispatch(isCreateCaseBl(true))
        if (props.loadedSectionId !== 0) {
          dispatch(getCreateCaseSection())
        }
        dispatch(SectionAlertAction.clearSectionAlert())
        history.push('/case')
        break
      case 'dashboard':
        const { startDate, endDate } = getDashboardFilterDateInfo()
        history.push('/dashboard')
        dispatch(isCreateCaseBl(true));
        dispatch(clearDashboardData());
        dispatch(setSearchFiltersData([]));
        dispatch(getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, startDate, endDate));
        break
      case 'reports':
        history.push('/reports');
        dispatch(UpdateFieldSorting([]));
        dispatch(resetPaginationState());
        break
      case 'userprofile':
        history.push('/userprofile')
        break
      case 'data-management':
        history.push('/datamanagement')
        break
      default:
        history.push('/dashboard')

    }
  }

  //get permissions from store
  const permissions = props.dashboardFilterData;

  //set defaut values
  const [state, setstate] = useState({});
  const [createUserAction, setCreateUserAction] = useState(false)
  const [workQueueUserAction, setWorkQueueUserAction] = useState(false)
  const [dataManagementUserAction, setDataManagementUserAction] = useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [referenceDataAction, setReferenceDataAction] = useState('none')
  const [referenceDataUrl, setReferenceDataUrl] = useState(null)

  const initialState = {
    selectedComponentName: [],
  }

  const askForConfirmation = (component) => {
    setDialogOpen(true);
    setstate({
      selectedComponentName: component
    })

  };

  const onCancel = () => {
    setDialogOpen(false);
  };

  const onConfirm = () => {
    var component = state.selectedComponentName;
    SideNavbarNavigation(component);
    dispatch(updateCaseEditableMode(false));
    onCancel()

  };

  const openDialog = (
    <CaseDialogBox
      open={dialogOpen}
      dialogTitle="Reject Process"
      dialogDescription={NAVIGATION_WARNING_MESSAGE}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )

  useEffect(() => {
    const useraction = permissions?.userActions
    useraction && useraction.forEach((element) => {
      if (element.userActionName == "Create Case") {
        setCreateUserAction(element.allowedActionBl)
      }
      else if (element.userActionName == "Work Queue") {
        setWorkQueueUserAction(element.allowedActionBl)
      }
      else if (element.userActionName == "Perform Admin Task") {
        setDataManagementUserAction(element.allowedActionBl)
      }
      else if (element.allowedActionBl && element.userActionName == "Data Request Form" && element.url != null) {
        setReferenceDataAction("block");
        setReferenceDataUrl(element.url);
      }
    })
  }, [permissions]);

  return (
    <div className={classes.root}>
      {openDialog}
      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerClosed }}
      >
        <Divider />
        <List>
          {/* <a href='/dashboard'> */}
          <a>
            <LightTooltip title="Dashboard" placement="right">
              <ListItem button key={'dashboard'}
                onClick={() => handleNavClick('dashboard')}
              >

                <ListItemIcon
                  classes={{
                    root: classes.MuiListItemIcon
                  }}
                >
                  <img src={Dashboard} alt="Dashboard" className={classes.sidebarIcon} />
                </ListItemIcon>
              </ListItem>
            </LightTooltip>

          </a>

          {(createUserAction) &&
            (
              <LightTooltip title="Create a Case" placement="right" >
                <ListItem button key={'add_case'}
                  onClick={() => handleNavClick('add_case')} >
                  <ListItemIcon
                    classes={{
                      root: classes.MuiListItemIcon
                    }}
                  >
                    <img src={AddCase} alt="Create a Case" className={classes.sidebarIcon} />
                  </ListItemIcon>
                </ListItem>
              </LightTooltip>
            )
          }

          <LightTooltip title="Notifications" placement="right" >
            <ListItem button key={'notification'}>
              <Badge badgeContent={notification.length} color='error'>
                <ListItemIcon
                  classes={{
                    root: classes.MuiListItemIcon
                  }}
                >
                  <img src={Notification} alt="Notifications" className={classes.sidebarIcon} />
                </ListItemIcon>
              </Badge>
            </ListItem>
          </LightTooltip>

          {(workQueueUserAction) &&
            <LightTooltip title="Work Queues" placement="right" >
              <ListItem button key={'report'}
                onClick={() => handleNavClick('reports')}>
                <ListItemIcon
                  classes={{
                    root: classes.MuiListItemIcon
                  }}
                >
                  <img src={Report} alt="Work Queues" className={classes.sidebarIcon} />
                </ListItemIcon>
              </ListItem>
            </LightTooltip>
          }
          {dataManagementUserAction &&
            <LightTooltip title="Data Management" placement="right" >
              <ListItem button key={'data-management'}
                onClick={() => handleNavClick('data-management')}>
                <ListItemIcon
                  classes={{
                    root: classes.MuiListItemIcon
                  }}
                >
                  <DriveFolderUploadOutlinedIcon sx={{ color: yellow[50], fontSize: 30 }} className={classes.sidebarIcon} />
                </ListItemIcon>
              </ListItem>
            </LightTooltip>
          }

        </List>
        <div className={classes.setting}>
          <LightTooltip style={{ display: referenceDataAction }} title="Submit a request" placement="right" >
            <ListItem button component="a"
              target='blank'
              href={referenceDataUrl}
            >
              <ListItemIcon
                classes={{
                  root: classes.MuiListItemIcon
                }}
              >
                <img src={EmailOutlinedIcon} alt="Submit a request" className={classes.sidebarIcon} />
              </ListItemIcon>
            </ListItem>
          </LightTooltip>
          <LightTooltip title="Settings" placement="right" >
            <ListItem button key={'userprofile'}
              onClick={() => handleNavClick('userprofile')}
            >
              <ListItemIcon
                classes={{
                  root: classes.MuiListItemIcon
                }}
              >
                <img src={Setting} alt="Settings" className={classes.sidebarIcon} />
              </ListItemIcon>
            </ListItem>
          </LightTooltip>
        </div>
      </Drawer>
    </div >
  );
}

export default connect(mapStateToProps)(SideNavbar)
