import React, { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { momentLocalizer, Calendar } from 'react-big-calendar'
import moment from 'moment'
import { StyledMonthView } from './monthcalendarview_style';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardMonthCaseData, startDate } from '../../reducers/DashboardReducer';
import { getDashboardCases, getDashboardMonthCases, toggleToMonthView, updateCalendarStartEndDate, updateLoaderState } from '../../actions/DashboardAction';
import MonthCalendarEvent from './MonthCalendarEvent';
import {
    DASHBOARD_DEFAULT_PAGE_NUMBER,
    DASHBOARD_DEFAULT_ITEM_PER_PAGE,
    DASHBOARD_DATE_FORMAT
} from '../../constants/GlobalConstants';

const localizer = momentLocalizer(moment);

const MonthCalendarView = (props) => {

    const monthCaseData = useSelector(dashboardMonthCaseData)
    const calendarDate = useSelector(startDate)
    const dispatch = useDispatch()


    useEffect(() => {
        const selected_start_date = moment(props.startOfMonth).startOf('month').format(DASHBOARD_DATE_FORMAT);
        const selected_end_date = moment(props.endOfMonth).endOf('month').format(DASHBOARD_DATE_FORMAT);       
        dispatch(updateLoaderState(true));
        dispatch(getDashboardMonthCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selected_start_date, selected_end_date));

    }, [])

    const navigateToCase = selectedDate => {
        const selected_start_date = moment(selectedDate.start).format(DASHBOARD_DATE_FORMAT);
        const selected_end_date = moment(selectedDate.start).format(DASHBOARD_DATE_FORMAT);
        const dates = { selected_start_date, selected_end_date };       
        dispatch(updateCalendarStartEndDate(dates));
        dispatch(getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selected_start_date, selected_end_date));
        dispatch(toggleToMonthView(false))
    };

    const allCalendarCases = []
    monthCaseData && monthCaseData.Items.forEach((cases) => {
        const caseData = cases.CaseStatusesCount
        var caseStatus = {}
        for (const key in caseData) {
            if (caseData[key] > 0) {
                if (key === 'Incomplete') {
                    caseStatus = {

                        title: `${caseData[key]} - ${key}`,
                        start: new Date(cases.ProcedureDate),
                        end: new Date(cases.ProcedureDate),
                        allDay: false,
                    }
                    allCalendarCases.push(caseStatus)
                }
                else if (key === 'Alert') {
                    caseStatus = {

                        title: `${caseData[key]} - ${key}`,
                        start: new Date(cases.ProcedureDate),
                        end: new Date(cases.ProcedureDate),
                        allDay: false,
                    }
                    allCalendarCases.push(caseStatus)
                }
                else if (key === 'Complete') {
                    caseStatus = {

                        title: `${caseData[key]} - ${key}`,
                        start: new Date(cases.ProcedureDate),
                        end: new Date(cases.ProcedureDate),
                        allDay: false,
                    }
                    allCalendarCases.push(caseStatus)
                }
                else if (key === 'Closed') {
                    caseStatus = {

                        title: `${caseData[key]} - ${key}`,
                        start: new Date(cases.ProcedureDate),
                        end: new Date(cases.ProcedureDate),
                        allDay: false,
                    }
                    allCalendarCases.push(caseStatus)
                }
                else if (key === 'Cancelled') {
                    caseStatus = {

                        title: `${caseData[key]} - ${key}`,
                        start: new Date(cases.ProcedureDate),
                        end: new Date(cases.ProcedureDate),
                        allDay: false,
                    }
                    allCalendarCases.push(caseStatus)
                }
            }
        }
    })

    // for Hiding the big-calendar top toolbar 
    const Toolbar = () => (
        <div> </div>
    )
    
    return (
        < >
            <Calendar
                localizer={localizer}
                views={{
                    month: StyledMonthView,
                }}
                onSelectEvent={navigateToCase}
                startAccessor="start"
                endAccessor="end"
                components={{
                    toolbar: Toolbar,
                    month: {
                        event: MonthCalendarEvent,
                    }
                }}

                events={allCalendarCases}
                date={new Date(props.startOfMonth)}
                defaultDate={new Date(props.startOfMonth)}
                style={{
                    height: '60rem',
                    overflow: 'hidden',
                    marginBottom: '10px'
                }}

            />
        </>
    )
}

export default MonthCalendarView
