import request from '../providers/RestServiceProvider'
import { APPROVE_REJECT_RULE, BASE_URL, GET_CASES, GET_DASHBOARD_FILTERS, GET_DASHBOARD_PRIMARY_SURGEONS, GET_DASHBOARD_SURGICAL_LOCATIONS, GET_MONTH_CASES, GET_NON_COMPLIANT_GUIDELINES } from '../constants/Locations';
import { executeCancelCaseServiceAPI } from './CaseDetailsService';
import { DASHBOARD_ERROR_TYPES } from '../constants/GlobalConstants';

export const getCases = async (requestData) => {
    return await request({
        url: GET_CASES,
        method: 'POST',
        data: requestData
    }).then((response) => {

        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
        }
    })
}
// this api is used to call approve reject recommendation on dashboard
export const approveRejectRule = async (requestData) => {
    const { CaseId, CaseRuleResultId, Action, RuleComments } = requestData
    const requestPayload = { Action, RuleComments }

    return await request({
        url: BASE_URL + `/case/${CaseId}/RuleResults/${CaseRuleResultId}`,
        method: 'POST',
        data: requestPayload
    }).then((response) => {
        return {
            data: response.data,
            status: response.status
        };
    }).catch((err)=>{
        return {
            data: err?.data,
            status: err?.status
        };
    })
}

export const getMonthCases = async (requestData) => {
    return await request({
        url: GET_MONTH_CASES,
        method: 'POST',
        data: requestData
    }).then((response) => {

        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    responseData: response.payload
                }
            }
        }
    })
}

export const getDashboardPrimarySurgeons = async () => {
    return await request({
        url: GET_DASHBOARD_PRIMARY_SURGEONS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const getDashboardSurgicalLocations = async () => {
    return await request({
        url: GET_DASHBOARD_SURGICAL_LOCATIONS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                return {
                    selections: response.payload
                }
            }
        }
    })
}

export const getDashboardFilters = async () => {
    return await request({
        url: GET_DASHBOARD_FILTERS,
        method: 'GET',
    }).then((response) => {
        if (response.status === 'Success') {
            if (response.payload) {
                var customisedFieldPermission = [];
                var objPatientName = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PatientFullName");
                if (objPatientName.length > 0) {
                    customisedFieldPermission.push(objPatientName[0]);
                }
                var objPatientDOB = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PatientDOB");
                if (objPatientDOB.length > 0) {
                    customisedFieldPermission.push(objPatientDOB[0]);
                }
                var objPrimarySurgeon = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "PrimarySurgeonId");
                if (objPrimarySurgeon.length > 0) {
                    customisedFieldPermission.push(objPrimarySurgeon[0]);
                }
                var objSurgicalLocation = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "SurgicalLocationId");
                if (objSurgicalLocation.length > 0) {
                    customisedFieldPermission.push(objSurgicalLocation[0]);
                }
                var objHospitalMrn = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "HospitalMRN");
                if (objHospitalMrn.length > 0) {
                    customisedFieldPermission.push(objHospitalMrn[0]);
                }
                var objCaseId = response.payload.fieldPermissions.filter(element => element.fieldMappingId == "CaseId");
                if (objCaseId.length > 0) {
                    customisedFieldPermission.push(objCaseId[0]);
                }
                response.payload.fieldPermissions = customisedFieldPermission;
                return {
                    responseData: response.payload
                }
            }
        }
    })
}

// This function is used to call the API to get non-compliant guidelines

export const getNonCompliantGuidelinesByCaseId = async (caseId) => {
    return await request({
        url: BASE_URL + `/case/${caseId}/RuleResults`,
        method: 'GET'
    }).then((response) => {
        if (response.status == 204) {
            throw response
        } else {
            return {
                data: response.data,
                status: response.status
            };
        }
    }).catch(error => {
        if (error.status == 403) {
            throw {
                type: DASHBOARD_ERROR_TYPES.UNAUTHORIZED,
                data: error.data
            }
        }
        if(error.status == 204) {
            throw{
                type : DASHBOARD_ERROR_TYPES.NO_GUIDELINES,
                data : error.data
            }
        }
        throw {
            type: 'error',
            data: error.data
        }
    });
}

const dashboardService = {
    getCases,
    getDashboardFilters,
    getDashboardPrimarySurgeons,
    getDashboardSurgicalLocations,
    getMonthCases,
    getNonCompliantGuidelinesByCaseId,
    approveRejectRule
}

export default dashboardService;