import LocalStorageProvider from "../providers/LocalStorageProvider";

export const BASE_URL = process.env.REACT_APP_BASE_API_URL
// BookingSheet
export const GET_BOOKING_SHEET_DETAILS = `${BASE_URL}/bookingsheet/GetBookingSheet?serviceLineId=`;
export const GET_SECTION_DETAILS = `${BASE_URL}/BookingSheet/GetHierarchicalSectionBySectionId?sectionId=`;
export const GET_SECTION_DETAILS_BY_SECTIONID_CASEID = `${BASE_URL}/Case/GetBookingSheetBySectionIdCaseId?`;
export const GET_SECTION_DETAILS_BY_SECTIONID_CASEID_DOCUMENTID = `${BASE_URL}/Case/GetBookingSheetWithDocumentBySectionIdCaseIdDocumentId?`;
export const GET_BOOKING_SHEET_SECTIONS = `${BASE_URL}/BookingSheet/GetSectionsByBookingSheetIdAsync?bookingSheetId=`;
export const GET_CASES = `${BASE_URL}/CaseDashBoard/GetCaseDashboardDetails`;
export const APPROVE_REJECT_RULE = `${BASE_URL}/Case/UpdateCaseRuleResultInformation`;
export const GET_MONTH_CASES = `${BASE_URL}/CaseDashBoard/GetCaseDashboardDetailsMonthView`;
export const GET_DASHBOARD_FILTERS = `${BASE_URL}/UserPermission/SearchPermissions`;
export const CREATE_CASE = `${BASE_URL}/Case/CreateCase`;
export const SAVE_CASE = `${BASE_URL}/Case/SaveCase`;
export const CANCEL_CASE = `${BASE_URL}/Case/CancelCase`;
export const GET_DASHBOARD_PRIMARY_SURGEONS = `${BASE_URL}/DashboardFilter/GetSurgeonListAsync?`;
export const GET_DASHBOARD_SURGICAL_LOCATIONS = `${BASE_URL}/DashboardFilter/GetSurgicalLocationListAsync?`;
export const SAVE_VENDOR_REP_CONFORM_ALL_PRODUCTS = `${BASE_URL}/Case/SaveVendorRepConfirmAllProducts`;
//export const GET_REFERENCE_URL = `${BASE_URL}/CreateCaseFilter/LoadReferenceData`;
export const GET_REFERENCE_URL = `${BASE_URL}/BookingSheet/GetReferenceDataByMappingId`;
export const GET_TENANT_USERINFO_URL = `${BASE_URL}/Account/GetUserInfoAsync`;
export const GET_ALLTENANTS_URL = `${BASE_URL}/Account/Tenants`;
export const UPDATE_SECTION_STATUS_BY_CASE_ID_SECTION_ID = `${BASE_URL}/Case/UpdateSectionStatusByCaseIdSectionId`

export const GET_USERPROFILE_INFO_URL = `${BASE_URL}/Account/GetUserDetailedInfoAsync`;
export const GET_USER_NOTIFICATION_PREFERENCES_URL = `${BASE_URL}/Account/GetUsersNotificationProfileAsync`;
export const SET_USER_NOTIFICATION_PREFERENCES_URL = `${BASE_URL}/Account/SaveUsersNotificationAsync`;
export const ACKNOWLEDGE_EMR_URL = `${BASE_URL}/CAT/UpdateAcknowledgementAsync`;

// Auth URL'S
export const KEY_BASE_URL = process.env.REACT_APP_BASE_API_AUTH_URL;
export const KEY_LOGIN_URL = "/connect/token";
export const KEY_LOGOUT_URL = "/Account/Logout";
export const KEY_REFRESH_LOGIN_URL = '/connect/token'
export const KEY_AUTHORIZE_URL = "/connect/authorize";
export const USERINFO_URL = "/connect/userinfo";
export const KEY_GET_ACCESS_TOKEN_URL = "/authentication/getAccessToken";
export const KEY_DASHBOARD_DETAILS_URL = "/dashboard/getDashboardDetails";

// Password 
export const FORGET_PASSWORD_URL = `${KEY_BASE_URL}/Account/ForgotPassword`;
export const RESET_PASSWORD_URL = `${KEY_BASE_URL}/Account/ResetPassword`;


//Multi-user locking
export const SIGNAL_R_RECEIVE_MESSAGE_URL = `${BASE_URL}/RealTime/Case`;

export const REJECT_DOCUMENT = `${BASE_URL}/Document/RejectDocument`;
export const REP_CONFIRMATION_PRODUCTS_URL = `${BASE_URL}/Product/RepConfirmation`;

//DataUpload
export const ADMIN_API_URL = process.env.REACT_APP_BASE_API_ADMIN_URL
export const GET_DATA_UPLOAD_TYPES_URL = `${ADMIN_API_URL}/MasterAdminDataUploadType/GetListAsync`;
export const DATA_UPLOAD_URL = `${ADMIN_API_URL}/DataUpload/`;

// Report Download
export const REPORT_DOWNLOAD_URL = `${ADMIN_API_URL}/DataUpload/Report/`;
export const GET_PROCEDURE_LOCATION_URL = `${ADMIN_API_URL}/DataUpload/GetProcedureLocations`;
export const WORK_QUEUE_LIST_URL = `${BASE_URL}/CaseFilters/WorkQueues`;

// Tray Section 
export const GET_TRAY_SECTION = `${BASE_URL}/Case/GetTrayPopupBookingSheet`;

//CAT
export const GET_AMENDMENTS_BY_CASE_ID = `${BASE_URL}/CAT/GetAmendmentsByCaseId`;

//Vendor Confirmation Due
export const GET_VENDOR_CONFIRMATION_DUE = `${BASE_URL}/CaseFilters/VendorConfirmationDue`;
