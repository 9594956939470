import React, { useState } from 'react'
import { Button, Badge, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    CASE_NAVIGATION_TITLE, CASE_NAVIGATION_BULLET_COMPLETE, PAPER_BG, INCOMPLETE,
    CASE_NAVIGATION_BULLET_INPROGRESS, ICON_RED
} from '../../res/values/Colors'
import * as Dimens from '../../res/values/Dimens'
import classNames from 'classnames'
import Controls from '../controls/Controls'
import ErrorIcon from '@mui/icons-material/Error';
import { CANCEL_CASE_SECTION_ID, CREATE_CASE_SECTION_ID, CANCEL_CASE_STATUS_ID, RULE_STATUS_ACTION_PENDING, RULE_STATUS_ACTION_REJECT } from '../../constants/GlobalConstants';
import { CLOSED } from '../../constants/CaseStatuses';
import { useHistory } from "react-router-dom";
import { getCancelCaseSection,setCreateCaseState, updateDisableNavigationStatus, updateSectionGroupLoaderStatus, 
    updateCaseEditableMode, updateCaseAmendmentBlStatus, sendSectionReleaseInfo, rulePopUpOpen, getCaseRuleResults } from '../../actions/CaseDetailsAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import { store } from '../../store';
import Skeleton from '@mui/material/Skeleton';
import { getCaseNavigationLoader, getDisableNavigationSectionStatus, getShowGuidelineButton, getGuidelineStatus } from '../../reducers/CaseDetailsReducer';
import * as constants from '../../constants/GlobalConstants';
import CaseDialogBox from './CaseDialogBox';
import * as Colors from '../../res/values/Colors'

const options = {
    labels: {
      confirmable: "OK",
      cancellable: "CANCEL"
    }
  }

const useStyles = makeStyles(theme => ({
    root: {
        position: 'sticky', top: 75
    },
    paper: {
        position: 'relative',
        display: 'block',
        padding: '16px',
        height: 'auto',
        width: 'auto',
        borderRadius: Dimens.BORDER_RADIUS,
        background: PAPER_BG,
    },
    headline: {
        textAlign: "left",
        color: CASE_NAVIGATION_TITLE,
    },
    blankBullet: {
        height: Dimens.BULLET_DIMENSION,
        width: Dimens.BULLET_DIMENSION,
        marginRight: Dimens.CASE_NAVIGATION_BULLET_MARGIN,
        display: "flex"
    },
    bullet: {
        height: Dimens.BULLET_DIMENSION,
        width: Dimens.BULLET_DIMENSION,
        verticalAlign: 'middle',
        borderRadius: '50%',
        marginRight: Dimens.CASE_NAVIGATION_BULLET_MARGIN,
        color: "#fff",
        fontSize: "12px",
        background: CASE_NAVIGATION_BULLET_INPROGRESS,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    alertBullet: {
        height: '21px',
        width: '21px',
        verticalAlign: 'middle',
        color: 'red',
        marginLeft: Dimens.CASE_NAVIGATION_BULLET_MARGIN,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    bulletComplete: {
        background: CASE_NAVIGATION_BULLET_COMPLETE
    },
    bulletIncomplete: {
        background: CASE_NAVIGATION_BULLET_INPROGRESS
    },
    sectionListItem: {
        display: 'block',
        cursor: 'pointer',
        padding: Dimens.CASE_NAVIGATION_ITEM_PADDING
    },
    sectionListItemDisabled: {
        display: 'block',
        padding: Dimens.CASE_NAVIGATION_ITEM_PADDING,
        opacity: .5,
    },
    selectedSectionListItem: {
        backgroundColor: '#fff'
    },
    sectionItem: {
        textAlign: 'left',
        verticalAlign: 'middle',
        display: 'inline-block',
        cursor: 'pointer',

    },
    navigationDisable: {
        pointerEvents: 'none',
        opacity: .5,

    }
}))

const useGuideLineStyles = makeStyles(theme => ({
    guideLineBtn: {
        borderColor: (props) => props.colorBorder,
        color: Colors.GUIDELINE_TEXT,
        minWidth: '100%',
        borderRadius: '5px !important',
        '&:hover': {
            borderColor: (props) => props.colorBorder,
            backgroundColor: (props) => props.colorBg,
        },
    },
    guideLineBadge: {
        minWidth: '100%',
        '& .MuiBadge-dot': {
            backgroundColor: (props) => props.colorBg,
        },
        '& .MuiBadge-colorPrimary': {
            backgroundColor: (props) => props.colorBg,
            color: (props) => props.colorText,
        },
    }
}))


const initialState = {
    selectedSectionId: CREATE_CASE_SECTION_ID,
    selectedSectionInfo:[],
}

export default function CaseNavigation(props) {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const stateData = store.getState();
    var caseNavigationLoader = useSelector(getCaseNavigationLoader);
    const SectionsDetails = useSelector(state => state.caseDetails.sectionDetails);
    const isCaseEditable = useSelector(state => state.caseDetails.isCaseEditable);
    const disableNavigationSection = useSelector(getDisableNavigationSectionStatus);
    const showGuideline = useSelector(getShowGuidelineButton);
    const guideLineStatus = useSelector(getGuidelineStatus);
    var currentSectionId = SectionsDetails != null ? SectionsDetails.sectionId : null;
    var hideCancelCaseButton = false;
    var caseStatusId = SectionsDetails != null ? SectionsDetails.caseStatusId : null;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    if (caseStatusId == CANCEL_CASE_STATUS_ID) {
        hideCancelCaseButton = true;
    }

    const askForConfirmation = () => {
        setDialogOpen(true);
    };

    const onCancel = () => {
        setDialogOpen(false);
    };

    const onConfirm = () => { 
        cancelCase();
        dispatch(sendSectionReleaseInfo());
        dispatch(updateCaseEditableMode(false));
        dispatch(updateCaseAmendmentBlStatus(false));        
        onCancel();        
    };
    const openDialog = (
        <CaseDialogBox
            open={dialogOpen}
            dialogTitle = "Reject Process"
            dialogDescription = {constants.NAVIGATION_WARNING_MESSAGE}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    )
    const handleCaseCancel = async () => {  
        if(isCaseEditable)
        {
            askForConfirmation();
            
        }
        else
        {
            cancelCase();
        }
    }

    const cancelCase = () =>
    {
        dispatch(updateDisableNavigationStatus(true))
        dispatch(setCreateCaseState(false));
        dispatch(updateSectionGroupLoaderStatus(true));
        dispatch(getCancelCaseSection(CANCEL_CASE_SECTION_ID, props.caseId));        
        const pageUrl = '/case/' + props.caseId + constants.CANCEL_CASE_PAGE_URL;
        history.push(pageUrl); 
    }

    const getGuideLineInfo = () => {
        dispatch(rulePopUpOpen(true))
        dispatch(getCaseRuleResults(props.caseId))
    }

    //Custom button for guideline box
    const GuidelineButton = ({ data, handleClick, children }) => {
        let colorBg = ""
        let colorBorder = ""
        if(data.status.toUpperCase() == RULE_STATUS_ACTION_PENDING) {
            colorBg = Colors.RULE_PENDING_BG
            colorBorder = Colors.RULE_PENDING
        } else if(data.status.toUpperCase() == RULE_STATUS_ACTION_REJECT) {
            colorBg = Colors.RULE_REJECTED_BG
            colorBorder = Colors.RULE_REJECTED
        } else {
            colorBg = Colors.RULE_APPROVED_BG
            colorBorder = Colors.RULE_APPROVED
        }

        const guideLineClass = useGuideLineStyles({colorBorder, colorBg})
        return (
            <Button
                variant="outlined"
                onClick={handleClick}
                className={guideLineClass.guideLineBtn}
            >
                {children}
            </Button>
        )
    }

    //Badge to show count
    const CustomBadge = ({ data, children }) => {
        let colorBg = ""
        if(data.status.toUpperCase() == RULE_STATUS_ACTION_PENDING) {
            colorBg = Colors.RULE_PENDING
        } else if(data.status.toUpperCase() == RULE_STATUS_ACTION_REJECT) {
            colorBg = Colors.RULE_REJECTED
        } else {
            colorBg = Colors.RULE_APPROVED
        }

        const textColor = Colors.BADGE_TEXT

        const guideLineClass = useGuideLineStyles({colorBg, textColor})
        return (
            <Badge
                max={999}
                color="primary"
                badgeContent={data.count}
                className={guideLineClass.guideLineBadge}
            >
                {children}
            </Badge>
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.paper}>
                {openDialog}
                {(showGuideline && guideLineStatus?.count > 0) && (
                    <Box sx={{mb: 2}}>
                        <CustomBadge data={guideLineStatus}>
                            <GuidelineButton
                                data={guideLineStatus}
                                handleClick={getGuideLineInfo}
                            >
                                Guidelines
                        </GuidelineButton>
                        </CustomBadge>
                    </Box>
                )}
                
                <Controls.Typography
                    variant='h6'
                    className={classes.headline}>{props.navItems.title}</Controls.Typography>

                {caseNavigationLoader && props.sectionId != CREATE_CASE_SECTION_ID ?
                    <>
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                        <Skeleton variant="text" width="218px" height="40px" />
                    </>
                    : props?.navItems?.sections?.map(section => (
                        <div style={{ display: 'flex', alignItems: 'center' }} className={
                            classNames(
                                disableNavigationSection ? classes.navigationDisable : null,
                                props.sectionId != CREATE_CASE_SECTION_ID ? classes.sectionListItem : classes.sectionListItemDisabled,
                                section.isSelected ? classes.selectedSectionListItem : null
                            )}
                            key={section.id}
                            onClick={() => props.sectionId != CREATE_CASE_SECTION_ID ? props.handleCaseNavigation(section) : null}

                        >
                            {section.showSectionStatusBl && <span className={
                                classNames(
                                    classes.bullet,
                                    section.isSectionComplete ?
                                        classes.bulletComplete : classes.bulletIncomplete
                                )
                            } ></span>}
                            {!section.showSectionStatusBl && <span className={
                                classNames(
                                    classes.blankBullet
                                )
                            } ></span>}
                            {section.count > 0 &&
                                <>
                                    <Badge badgeContent={section.count > 0 ? section.count : ""} color="primary">
                                        <Controls.Typography
                                            className={classes.sectionItem}
                                        >{section.label}
                                        </Controls.Typography>
                                    </Badge>
                                </>
                            }

                            {section.count == 0 &&
                                <Controls.Typography
                                    className={classes.sectionItem}
                                >{section.label}
                                </Controls.Typography>}
                            {section.alertStatusBl == true && section.showAlertBl == true && (
                                <ErrorIcon fontSize="inherit" className={
                                    classNames(
                                        classes.alertBullet
                                    )
                                } />
                            )}
                        </div>
                    ))
                }

            </div>            
            { (props.canCancelCase || caseStatusId == CANCEL_CASE_STATUS_ID) && (currentSectionId != CREATE_CASE_SECTION_ID)  &&
                <div className={classes.paper} style={{ marginTop: '15px' }}>

                    {

                        (caseStatusId == CLOSED)
                            ?
                            <div style={{ padding: '8px', border: '1px solid ', borderColor: ICON_RED, borderRadius: '6px' }}  >
                                <Controls.Typography
                                    className={classes.sectionItem}
                                    style={{
                                        textAlign: 'left',
                                        verticalAlign: 'bottom',
                                        color: ICON_RED
                                    }}

                                >
                                    Case Closed
                                </Controls.Typography>
                            </div>
                            :
                            (caseStatusId == CANCEL_CASE_STATUS_ID)
                                ?
                                <div style={{ padding: '8px', border: '1px solid ', borderColor: ICON_RED, borderRadius: '6px' }} >
                                    < Controls.Typography
                                        primary
                                        className={classes.sectionItem}
                                        style={{
                                            textAlign: 'left',
                                            verticalAlign: 'bottom',
                                            color: ICON_RED

                                        }}
                                    >
                                        Case Cancelled
                                    </Controls.Typography>
                                </div>
                                :
                                <div style={{ padding: '8px' }} className={classNames(disableNavigationSection ? classes.navigationDisable : null,
                                    currentSectionId == CANCEL_CASE_SECTION_ID ? classes.selectedSectionListItem : null)} >
                                    <Controls.Typography
                                        className={classes.sectionItem}
                                        style={{
                                            textAlign: 'left',
                                            verticalAlign: 'bottom'
                                        }}
                                        onClick={handleCaseCancel}
                                    >
                                        Cancel Case
                                    </Controls.Typography>
                                </div>

                    }

                </div>
            }

        </div>
    )
}
