import {
  CLEAR_DASHBOARD_TO_INITIAL_STATE,
  UPDATE_CALENDAR_START_DATE_END_DATE,
  GET_CASES_SUCCESS,
  GET_CASES_FAIL, GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_FAIL,
  IS_DASHBOARD_BL,
  UPDATE_DASHBOARD_FILTER_VALUE,
  GET_MONTH_CASES_SUCCESS,
  GET_MONTH_CASES_FAIL,
  TOGGLE_TO_MONTH_VIEW,
  UPDATE_DASHBOARD_CASE_LOADER,
  GET_MONTH_CASES, GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS,
  GET_DASHBOARD_PRIMARY_SURGEONS_FAIL,
  GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS,
  GET_DASHBOARD_SURGICAL_LOCATION_FAIL,
  UPDATE_DASHBOARD_START_DATE,
  UPDATE_DASHBOARD_END_DATE,
  GET_NON_COMPLAINT_GUIDELINES_SUCCESS,
  GET_NON_COMPLAINT_GUIDELINES_FAILURE,
  UPDATE_RULE_FAILURE
} from '../constants/ActionTypes';
import moment from 'moment'
import { GET_CASES } from '../constants/Locations';
import {
  DASHBOARD_DATE_FORMAT
} from '../constants/GlobalConstants';

const initialState = {
  casesData: null,
  error: null,
  loading: false,
  monthCasesData: null,
  dashboardToggle: false,
  dashboardFiltersData: null,
  isDashboardSearch: false,
  primarySurgeonList: null,
  surgicalLocationList: null,
  calendarStartDate: moment().format(DASHBOARD_DATE_FORMAT),
  calendarEndDate: moment().format(DASHBOARD_DATE_FORMAT)
};

const ACTION_HANDLERS = {
  [CLEAR_DASHBOARD_TO_INITIAL_STATE]: (state, action) => ({
    ...state,
    casesData: null,
    error: null,
    loading: false,
    monthCasesData: null,
    dashboardToggle: false,
    isDashboardSearch: false,
    calendarStartDate: moment().format(DASHBOARD_DATE_FORMAT),
    calendarEndDate: moment().format(DASHBOARD_DATE_FORMAT)
  }),

  [UPDATE_CALENDAR_START_DATE_END_DATE]: (state, action) => {
    return {
      ...state,
      calendarStartDate: moment(action.payload.selected_start_date).format(DASHBOARD_DATE_FORMAT),
      calendarEndDate: moment(action.payload.selected_end_date).format(DASHBOARD_DATE_FORMAT)
      // calendarStartDate: moment(action.payload.selected_start_date).format('MM-DD-YY 00:00:00.000'),
      // calendarEndDate: moment(action.payload.selected_end_date).format('MM-DD-YY 00:00:00.000')
    };
  },
  [GET_MONTH_CASES]: (state, action) => {
    return {
      ...state,
      loading: action.loading
    }
  },
  [GET_MONTH_CASES_SUCCESS]: (state, action) => {
    return {
      ...state,
      monthCasesData: action.payload,
      loading: action.loading
    };
  },
  [GET_MONTH_CASES_FAIL]: (state, action) => {
    return {
      ...state,
      monthCasesData: null
    };
  },
  [TOGGLE_TO_MONTH_VIEW]: (state, action) => {
    return {
      ...state,
      dashboardToggle: action.payload

    };
  },

  [GET_CASES]: (state, action) => ({
    ...state,
    caseData: action.payload

  }),

  [UPDATE_DASHBOARD_CASE_LOADER]: (state, action) => {
    return {
      ...state,
      loading: action.loading
    }

  },
  [GET_CASES_SUCCESS]: (state, action) => {
    return {
      ...state,
      casesData: action.payload,
      error: null,
      loading: action.loading
    };
  },
  [GET_CASES_FAIL]: (state, action) => {
    return {
      ...state,
      casesData: null,
      error: action.payload,
      loading: false
    };
  },
  [GET_DASHBOARD_FILTERS_SUCCESS]: (state, action) => {
    return {
      ...state,
      dashboardFiltersData: action.payload
    };
  },
  [GET_DASHBOARD_FILTERS_FAIL]: (state, action) => {
    return {
      ...state,
      dashboardFiltersData: null
    };
  },
  [IS_DASHBOARD_BL]: (state, action) => {
    return {
      ...state,
      isDashboardSearch: action.payload
    };
  },
  [UPDATE_DASHBOARD_FILTER_VALUE]: ({ dashboardFiltersData, ...rest }, action) => {
    return {
      ...rest,
      dashboardFiltersData: action.payload
    };
  },
  [GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      primarySurgeonList: action.payload
    };
  },
  [GET_DASHBOARD_PRIMARY_SURGEONS_FAIL]: (state, action) => {
    return {
      ...state,
      primarySurgeonList: []
    };
  },
  [GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      surgicalLocationList: action.payload
    };
  },
  [GET_DASHBOARD_SURGICAL_LOCATION_FAIL]: (state, action) => {
    return {
      ...state,
      surgicalLocationList: []
    };
  },
  [UPDATE_DASHBOARD_START_DATE]: (state, action) => {
    return {
      ...state,
      calendarStartDate: moment(action.payload).format(DASHBOARD_DATE_FORMAT),
    };
  },
  [UPDATE_DASHBOARD_END_DATE]: (state, action) => {
    return {
      ...state,
      calendarEndDate: moment(action.payload).format(DASHBOARD_DATE_FORMAT),
    };
  },
  [GET_NON_COMPLAINT_GUIDELINES_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    casesData: action.payload,
  }),
  [GET_NON_COMPLAINT_GUIDELINES_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload.error,
    casesData: action.payload.data,
  }),
  [UPDATE_RULE_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload.error
    };
  },
};


export default function dashboard(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}


export const isLoggedIn = state => state.isLoggedIn;
export const dashboardCaseData = state => state.dashboard.casesData;
export const dashboardMonthCaseData = state => state.dashboard.monthCasesData;
export const dashboardToggle = state => state.dashboard.dashboardToggle;
export const dashboardFilterFields = state => state.dashboard.dashboardFiltersData;
export const isDashboardBl = state => state.dashboard.isDashboardSearch;
export const getLoading = state => state.dashboard.loading;
export const getPrimarySurgeonSelections = state => state.dashboard.primarySurgeonList;
export const getSurgicalLocationSelections = state => state.dashboard.surgicalLocationList;
export const startDate = state => state.dashboard.calendarStartDate;
export const endDate = state => state.dashboard.calendarEndDate;
export const error = state => state.dashboard.error;

