import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as Colors from "../../res/values/Colors";
import makeStyles from "@mui/styles/makeStyles";
import {
  LBL_STATUS,
  LBL_TIME,
  LBL_PATIENT_NAME,
  LBL_DOB,
  LBL_PROCEDURA_LIST,
  LBL_PROCEDURE_DATE,
  LBL_LOCATION,
  LBL_MEDTEL_CASE_ID,
  LBL_PROCEDURE_UNIT,
  LBL_CONFIRMATION_HASH,
  DASHBOARD_CASE_NOT_FOUND,
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER,
  DASHBOARD_DEFAULT_ITEM_PER_PAGE,
  DASHBOARD_DEFAULT_PAGE_NUMBER,
  PermissionTypes,
  DASHBOARD_UNAUTHORIZED_VIEW_DIALOG_CONTENT_TEXT,
  DASHBOARD_UNAUTHORIZED_ACTION_DIALOG_CONTENT_TEXT,
  DASHBOARD_REJECT_BTN_LBL,
  DASHBOARD_APPROVE_BTN_LBL,
  LBL_RULE,
  LBL_CASE_DETAILS,
  LBL_RECOMMENDATION,
  LBL_NON_COMPLIANT_TABLE_MESSAGE,
  LBL_REVIEW_STATUS,
  LBL_COMMENTS,
  LBL_ACTIONS,
  DASHBOARD_DATE_FORMAT,
  DASHBOARD_ERROR_TYPES,
  APPROVE_STATUS_ID,
  RULE_APPROVE_DIALOG_TITLE,
  RULE_STATUS_ACTION_APPROVE,
  RULE_REJECT_DIALOG_TITLE,
  RULE_REJECT_BTN_LBL,
  RULE_STATUS_ACTION_REJECT,
  RULE_APPROVE_BTN_LBL,
  APPROVE_REJECT_NOCASEACESS_BACKEND_MSG,
  DASHBOARD_NO_GUIDELINES_DIALOG_CONTENT_TEXT,
  DASHBOARD_UNAUTHORIZED_DIALOG_CONTENT_TEXT,
  COMMON_DATE_FORMAT,
  COMMON_TIME_FORMAT,
} from "../../constants/GlobalConstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DashboardTablePagination from "./DashboardTablePagination";
import { useHistory } from "react-router-dom";
import Pending from "../../res/images/pending.png";
import Approved from "../../res/images/approved.png";
import Rejected from "../../res/images/rejected.png";
import {
  getDashboardCases,
  getNonCompliantGuidelines,
} from "../../actions/DashboardAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { error } from "../../reducers/DashboardReducer";
import CaseDialogBox from "../case/CaseDialogBox";
import moment from "moment";
import ApproveRejectDialog from "../dashboard/ApproveRejectDialog";
import { updateApproveRejectRule } from "../../actions/DashboardAction";

const TableCellHead = withStyles((theme) => ({
  root: {
    backgroundColor: Colors.CAT_TABLE_HEADER,
    color: theme.palette.common.white,
    paddingLeft: 10,
    paddingRight: 10,
  },
}))(TableCell);

const TableCellBody = withStyles((theme) => ({
  root: {
    minWidth: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  loader: {
    margin: 0,
  },
  innerTableContainer: {
    maxHeight: "300px",
    overflow: "auto",
  },
  innerTableCell: {
    backgroundColor: `${Colors.AMENDMENTS_LIST_HEADER} !important`,
    color: `${Colors.INNER_TABLE_CELL_COLOR} !important`,
    minWidth: 90
  },
  innerTableHeader: {
    backgroundColor: `${Colors.AMENDMENTS_LIST_HEADER} !important`,
    position: "relative",
    zIndex: 0,
  },
  tableContainer: {
    maxHeight: `calc(100vh - ${theme.spacing(33)})`,
  },
  tableHeader: {
    backgroundColor: Colors.CAT_TABLE_HEADER,
  },
  colorConfirm: {
    color: Colors.ICON_GR,
  },
  colorUnconfirm: {
    color: Colors.ICON_RED,
  },
  sortLabelRoot: {
    color: `${theme.palette.common.white} !important`, // Customize root color
    "&.MuiTableSortLabel-active": {
      color: `${theme.palette.common.white} !important`, // Active state color
    },
  },
  sortLabelIcon: {
    color: `${theme.palette.common.white} !important`, // Customize icon color
    "&.MuiTableSortLabel-active": {
      color: `${theme.palette.common.white} !important`, // Active state color for icon
    },
  },
  caseStatusIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    verticalAlign: "middle",
  },
  coulmnBox: {
    minHeight: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  expandIconCell: {
    padding: "5px 5px 5px 10px !important"
  }
}));

const MappingKeys = {
  PatientName: "PatientFullName",
  DocumentCount: "DocumentCount",
  PatientDOB: "PatientDOB",
  PrimarySurgeonName: "PrimarySurgeonName",
  HospitalMRN: "HospitalMRN",
  CaseId: "CaseId",
  ProcedureUnitName: "ProcedureUnitName",
  EncounterId: "EncounterId",
  ConfirmationNumber: "ConfirmationNumber",
  ServiceLineName: "ServiceLineName",
  CaseStatusId: "CaseStatusId",
  SurgicalLocationName: "SurgicalLocationName",
  BookingSheetId: "BookingSheetId",
  BookingSheetName: "BookingSheetName",
  ProcedureDate: "ProcedureDate",
  ProcedureTime: "ProcedureTime",
};

const ruleStatusPayload = {
  CaseRuleResultId: null,
  Action: "",
  RuleComments: "",
  CaseId: null
}

const DashboardTable = (props) => {
  const { data, checkCaseStatus } = props;
  const [tableData, setTableData] = React.useState({});
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [totalData, setTotalData] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(100);
  const history = useHistory();
  const [showDialog, setshowDialog] = React.useState(0);
  const [showApproveRejectDialog, setshowApproveRejectDialog] = React.useState("");
  const dashboardError = useSelector(error);
  const [ruleStatusRequestData, setRuleStatusRequestData] = React.useState(ruleStatusPayload);
  const [caseId, setCaseID] = React.useState();
  const [sortedCase, setSortedCase] = React.useState([]);

  const loading = false;
  const classes = useStyles();

  const dispatch = useDispatch();

  function serializeAndFormat(input) {
    const parsedObject = JSON.parse(input);

    const defaultKeys = {
      cptCode: "CPT",
      icdCode: "ICD",
    };
    const formatKey = (key) => {
      if (defaultKeys[key]) return defaultKeys[key];
      return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^[a-z]/, (char) => char.toUpperCase());
    };

    return Object.entries(parsedObject)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => <Box>{`${formatKey(key)} : ${value}`}</Box>);
  }

  const handleDialogConfirm = () => {
    setshowDialog("");
    if (dashboardError?.type === DASHBOARD_ERROR_TYPES.UNAUTHORIZED || dashboardError.data?.exception?.exceptionMessage == APPROVE_REJECT_NOCASEACESS_BACKEND_MSG) {
      refreshDashboarData();
    } else if (dashboardError?.type === DASHBOARD_ERROR_TYPES.UNAUTHORIZED_APPROVE_REJECT) {
      dispatch(getNonCompliantGuidelines(caseId, false));
    }
  };

  const handleConfirmApproveReject = (comment) => {
    const requestPayload = {
      ...ruleStatusRequestData,
      RuleComments: comment
    }
    dispatch(updateApproveRejectRule(requestPayload))
    setshowApproveRejectDialog("")
  };

  const refreshDashboarData = () => {
    const dashboardStartDate = sessionStorage.getItem("dashboardStartDate")
    const dashboardEndDate = sessionStorage.getItem("dashboardEndDate")
    var startDate = dashboardStartDate ? moment(dashboardStartDate).format(DASHBOARD_DATE_FORMAT) : moment(new Date()).format(DASHBOARD_DATE_FORMAT);
    var endDate = dashboardEndDate ? moment(dashboardEndDate).format(DASHBOARD_DATE_FORMAT) : moment(new Date()).format(DASHBOARD_DATE_FORMAT);
    dispatch(
      getDashboardCases(
        DASHBOARD_DEFAULT_ITEM_PER_PAGE,
        DASHBOARD_DEFAULT_PAGE_NUMBER,
        startDate,
        endDate,
      )
    );
  }
  // groupingName is used only for attachement column for display Attachments label during sorting
  const headCells = [
    {
      Alias: MappingKeys.CaseStatusId,
      ColumnName: LBL_STATUS,
      display: true,
      width: 30,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.ProcedureTime,
      ColumnName: LBL_TIME,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.PatientName,
      ColumnName: LBL_PATIENT_NAME,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.PatientDOB,
      ColumnName: LBL_DOB,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.PrimarySurgeonName,
      ColumnName: LBL_PROCEDURA_LIST,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.ProcedureDate,
      ColumnName: LBL_PROCEDURE_DATE,
      display: true,
      width: 80,
      showGroupingNameLbl: false
    },
    {
      Alias: MappingKeys.SurgicalLocationName,
      ColumnName: LBL_LOCATION,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.CaseId,
      ColumnName: LBL_MEDTEL_CASE_ID,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.ProcedureUnitName,
      ColumnName: LBL_PROCEDURE_UNIT,
      display: true,
      width: 80,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.ConfirmationNumber,
      ColumnName:
        props.dashboardCases?.Headers?.find(
          (item) => item.FieldMappingId === MappingKeys.ConfirmationNumber
        )?.FieldName || LBL_CONFIRMATION_HASH,
      display: true,
      width: 50,
      showGroupingNameLbl:true
    },
    {
      Alias: MappingKeys.DocumentCount,
      ColumnName: <AttachFileIcon fontSize="small" />,
      groupingName: "Attachments",
      display: true,
      width: 20,
      showGroupingNameLbl:true
    },
  ];

  React.useEffect(() => {
    let sortedCaseData = []
    let numRows = 0
    let groupedCaseData = {}
    let pageIdx = 0

    if (data.length > 0) {
      numRows = data.length

      if (numRows > itemsPerPage) {
        const pageCount = Math.ceil(numRows / itemsPerPage)
        if (pageCount >= pageNumber) {
          pageIdx = pageNumber
        }
      }

      sortedCaseData = sortByColumnID(orderBy, order)
      const paginatedCaseData = paginateData(sortedCaseData, pageIdx, itemsPerPage)
      groupedCaseData = groupByColumnName(orderBy, paginatedCaseData)
    }

    setTableData(groupedCaseData)
    setSortedCase(sortedCaseData)
    setTotalData(numRows)
    setPageNumber(pageIdx)
  }, [data]);

  React.useEffect(() => {
    if (dashboardError?.type == DASHBOARD_ERROR_TYPES.UNAUTHORIZED) {
      setshowDialog(DASHBOARD_UNAUTHORIZED_VIEW_DIALOG_CONTENT_TEXT);
    } else if (dashboardError?.type == DASHBOARD_ERROR_TYPES.UNAUTHORIZED_APPROVE_REJECT) {
      setshowDialog(dashboardError.data?.exception?.exceptionMessage == APPROVE_REJECT_NOCASEACESS_BACKEND_MSG ? DASHBOARD_UNAUTHORIZED_VIEW_DIALOG_CONTENT_TEXT : DASHBOARD_UNAUTHORIZED_ACTION_DIALOG_CONTENT_TEXT);
    } else if (dashboardError?.type == DASHBOARD_ERROR_TYPES.NO_GUIDELINES) {
      refreshDashboarData();
    }
  }, [dashboardError]);

  //To paginate case data
  function paginateData(value, page, perPage) {
    setPageNumber(page);
    setItemsPerPage(perPage);
    const startIndex = page * perPage;
    const paginatedData = value.slice(startIndex, startIndex + perPage);
    const result = paginatedData;
    return result;
  }

  //To group case data and update page number when page change
  const handlePageChange = (item) => {
    const paginatedTableInfo = paginateData(sortedCase, item, itemsPerPage);
    const groupedTableInfo = groupByColumnName(orderBy, paginatedTableInfo);
    setTableData(groupedTableInfo);
  };

  //To group case data and update page number when per-page change
  const handlePerPageChange = (item) => {
    setPageNumber(0)
    const paginatedTableInfo = paginateData(sortedCase, 0, item);
    const groupedTableInfo = groupByColumnName(orderBy, paginatedTableInfo);
    setTableData(groupedTableInfo);
  };

  //To group data according to the selected column
  const groupByColumnName = (column, value) => {
    return value.reduce((acc, event) => {
      const key = event[column];
      const columnGroup = '"' + key + '"';
      if (!acc[columnGroup]) {
        acc[columnGroup] = [];
      }
      acc[columnGroup].push(event);
      return acc;
    }, {});
  };

  //To sort grouped data by column name
  const sortByColumnID = (newOrderBy, orderValue = null) => {
    const isAsc = orderBy === newOrderBy && order.toLowerCase() === "asc";
    const sortOrder = isAsc ? "desc" : "asc";
    const toggledOrder = orderValue !== null ? orderValue : sortOrder;
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
    let tableInfo = [];

    if (
      newOrderBy == MappingKeys.ProcedureDate ||
      newOrderBy == MappingKeys.PatientDOB
    ) {
      tableInfo = sortByDate(newOrderBy, toggledOrder);
    } else if (newOrderBy == MappingKeys.ProcedureTime) {
      tableInfo = sortByTime(newOrderBy, toggledOrder)
    } else if (
      newOrderBy == MappingKeys.CaseId ||
      newOrderBy == MappingKeys.DocumentCount||
      newOrderBy == MappingKeys.CaseStatusId
    ) {
      tableInfo = sortByNumber(newOrderBy, toggledOrder);
    } else {
      tableInfo = sortByString(newOrderBy, toggledOrder);
    }

    return tableInfo
  };

  const handleColumnSort = (column) => {
    const sortedCaseData = sortByColumnID(column)
    const paginatedCaseData = paginateData(sortedCaseData, 0, itemsPerPage);
    const groupedCaseData = groupByColumnName(column, paginatedCaseData)
    setTableData(groupedCaseData);
    setSortedCase(sortedCaseData)
  }

  //To sort the table data if column value is number
  const sortByNumber = (column, order) => {
    return [...data].sort((a, b) => order == "asc" ? a[column] - b[column] : b[column] - a[column]);
  };

  //To sort the table data if column value is string
  const sortByString = (column, order) => {
    const sorted = [...data].sort((a, b) => {
      if (order === 'asc') {
        return a[column].localeCompare(b[column]);
      } else {
        return b[column].localeCompare(a[column]);
      }
    });

    return sorted
  };

  //To sort the table data if column value is date
  const sortByDate = (column, order) => {
    const sorted = [...data].sort((a, b) => {
      const dateA = new Date(a[column]);
      const dateB = new Date(b[column]);

      if (order === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    return sorted
  };

  //To sort the table data if column value is time
  const sortByTime = (column, order) => {
    const convertTo24Hour = (time) => {
      if (time === "N/A") return -1;
      const [hourMin, modifier] = time.split(' ');
      let [hour, minutes] = hourMin.split(':');
      hour = parseInt(hour);
      minutes = parseInt(minutes);

      if (modifier === 'PM' && hour !== 12) {
        hour += 12;
      } else if (modifier === 'AM' && hour === 12) {
        hour = 0;
      }

      return hour * 60 + minutes;
    };

    const sorted = [...data].sort((a, b) => {
      const timeA = convertTo24Hour(a[column]);
      const timeB = convertTo24Hour(b[column]);

      if (order === 'asc') {
        return timeA - timeB;
      } else {
        return timeB - timeA;
      }
    });

    return sorted
  };

  //To check show/hide column according to dashboard header in api response
  const showDashBoardCoulmn = (mappingId) => {
    const coulmnStatus = props.dashboardCases?.Headers.some(
      (item) => item.FieldMappingId == mappingId
    );
    return coulmnStatus;
  };

  const RenderCollapsableTable = ({ item }) => {
    let permissions = {
      RuleApproveBtn: false,
      RuleRejectBtn: false,
      RuleName: false,
      RuleRecommendation: false,
      RuleMessage: false,
      ApproverComments: false,
      ApproverStatus: false,
      RuleParameters: false,
    };
    // maintaining approve and reject button disabled state
    let btnPermissionsEnabled = {
      RuleApproveBtn: false,
      RuleRejectBtn: false,
    };

    // Function to check permission and update the permissions object
    const checkPermission = (fieldMappingObject) => {
      if (fieldMappingObject.fieldPermissionTypeId === PermissionTypes.HIDE) {
        return;
      }
      if (PermissionTypes.ENABLE_FIELD === fieldMappingObject.fieldPermissionTypeId ||
        PermissionTypes.DISABLE_FIELD === fieldMappingObject.fieldPermissionTypeId) {
        if (permissions.hasOwnProperty(fieldMappingObject.fieldMappingId)) {
          permissions[fieldMappingObject.fieldMappingId] = true;
        }
        if (btnPermissionsEnabled.hasOwnProperty(fieldMappingObject.fieldMappingId)) {
          btnPermissionsEnabled[fieldMappingObject.fieldMappingId] = PermissionTypes.ENABLE_FIELD === fieldMappingObject.fieldPermissionTypeId;
        }
      }
    };

    // Iterate over fieldPermissions to update the permission flags
    (item.fieldPermissions || []).forEach((item) => {
      checkPermission(item);
    });

    const showRuleApproveRejectDialog = (action, caseId, caseRuleResultId) => {

      setshowApproveRejectDialog(action)
      setRuleStatusRequestData({
        ...ruleStatusRequestData,
        CaseId: caseId,
        Action: action,
        CaseRuleResultId: caseRuleResultId
      })
    }

    return (
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={12}>
          <Collapse in={item?.open} timeout="auto" unmountOnExit>
            {item.nonCompliantGuidelinesLoading ? (
              <Box className={classes.loader}>
                <LinearProgress />
              </Box>
            ) : (
              <Box sx={{ padding: '20px' }}>
                {item.caseRules?.length > 0 && <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {permissions.RuleName && <TableCell className={classes.innerTableCell}>
                          {LBL_RULE}
                        </TableCell>}
                        {permissions.RuleParameters && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                        >
                          {LBL_CASE_DETAILS}
                        </TableCell>}
                        {permissions.RuleRecommendation && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                        >
                          {LBL_RECOMMENDATION}
                        </TableCell>}
                        {permissions.RuleMessage && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                        >
                          {LBL_NON_COMPLIANT_TABLE_MESSAGE}
                        </TableCell>}
                        {permissions.ApproverStatus && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                        >
                          {LBL_REVIEW_STATUS}
                        </TableCell>}
                        {permissions.ApproverComments && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                        >
                          {LBL_COMMENTS}
                        </TableCell>}
                        {(permissions.RuleApproveBtn || permissions.RuleRejectBtn) && <TableCell
                          align="left"
                          className={classes.innerTableCell}
                          width="120px"
                        >
                          {LBL_ACTIONS}
                        </TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(item.caseRules || []).map((row, index) => {
                        const formattedData = row.ruleParameters
                          ? serializeAndFormat(row.ruleParameters)
                          : [];

                        return (
                          <TableRow key={index}>
                            {permissions.RuleName && <TableCell component="th" scope="row">
                              {row.ruleName}
                            </TableCell>}
                            {permissions.RuleParameters && <TableCell align="left"><Stack spacing={1}>
                              {formattedData.length > 0 ? (
                                formattedData.map((item, idx) => (
                                  <div key={idx}>{item}</div>
                                ))
                              ) : null}
                            </Stack></TableCell>}
                            {permissions.RuleRecommendation && <TableCell align="left">
                              {row.ruleRecommendation}
                            </TableCell>}
                            { permissions.RuleMessage && <TableCell align="left">
                              {row.ruleMessage}
                            </TableCell>}
                            { permissions.ApproverStatus && <TableCell align="left">

                              <Stack spacing={1}>
                                <Box>{row.approverStatus}</Box>
                                {row.approvedBy && (<>
                                  <Box>{row.approvedBy}</Box>
                                  <Box>{moment(row.approvedOn).format(COMMON_DATE_FORMAT)}</Box>
                                  <Box>{moment(row.approvedOn).format(COMMON_TIME_FORMAT)}</Box>
                                </>
                                )}
                              </Stack>
                            </TableCell>}
                            {permissions.ApproverComments && <TableCell align="left">
                              {row.approverComments}
                            </TableCell>}
                            {(permissions.RuleApproveBtn || permissions.RuleRejectBtn) && <TableCell >
                              <Stack direction="column" spacing={1}>
                                {permissions.RuleApproveBtn && (
                                  <Button variant="contained" size="small" onClick={() => {
                                    showRuleApproveRejectDialog(RULE_STATUS_ACTION_APPROVE, item.CaseId, row.caseRuleResultId)
                                  }}>
                                    {DASHBOARD_APPROVE_BTN_LBL}
                                  </Button>
                                )}
                                {permissions.RuleRejectBtn && (
                                  <Button variant="contained" size="small" onClick={() => {
                                    showRuleApproveRejectDialog(RULE_STATUS_ACTION_REJECT, item.CaseId, row.caseRuleResultId)
                                  }}>
                                    {DASHBOARD_REJECT_BTN_LBL}
                                  </Button>
                                )}
                              </Stack>
                            </TableCell>}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>}
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }

  const RenderTableBody = (props) => {
    const { row, rowInfo, index } = props;

    const handleGetNonCompliantGuidelinesData = (CaseId, Open) => {
      setCaseID(Open ? null : CaseId);
      dispatch(getNonCompliantGuidelines(CaseId, Open));
    };

    const orderByColumn = headCells.find((headCell) => headCell.Alias === orderBy);

    return (
      <>
        <TableRow hover>
          <TableCellBody scope="row" align="left" colSpan="12">
            {orderByColumn && orderByColumn.showGroupingNameLbl && <strong>{orderByColumn.groupingName ?? orderByColumn.ColumnName}:{' '}</strong>}
            {orderBy == MappingKeys.CaseStatusId ? (
              <img
                src={checkCaseStatus(parseInt(row.replace(/^"|"$/g, "")))}
                className={classes.caseStatusIcon}
                alt=""
              />
            ) : (
              <b>
                {/* {orderBy == MappingKeys.CaseId
                  ? row.replace(/^"|"$/g, "")
                  : row} */}
                {row.replace(/^"|"$/g, "")}
              </b>
            )}
          </TableCellBody>
        </TableRow>
        <>
          {rowInfo.map((item) => (
            <>
              <TableRow
                key={item.CaseId}
                sx={{ cursor: "pointer" }}
                hover
                onClick={() =>
                  history.push(
                    `case/${parseInt(item.CaseId)}/PatientInformation`
                  )
                }
              >
                <TableCell className={classes.expandIconCell}>
                  {item.CaseRuleResultsBl &&
                    (item.ApproverStatusId === APPROVE_STATUS_ID.PENDING ||
                      item.ApproverStatusId === APPROVE_STATUS_ID.APPROVED ||
                      item.ApproverStatusId === APPROVE_STATUS_ID.REJECTED) ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGetNonCompliantGuidelinesData(
                          item.CaseId,
                          Boolean(item?.open)
                        );
                      }}
                    >
                      {item.open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  ) : null}
                </TableCell>
                {showDashBoardCoulmn(MappingKeys.CaseStatusId) && (
                  <TableCellBody align="left">
                    <img
                      src={checkCaseStatus(item.CaseStatusId)}
                      className={classes.caseStatusIcon}
                      alt=""
                    />

                    <Box component="span" sx={{ mx: 0.5 }}>
                      {item.CaseRuleResultsBl &&
                        (item.ApproverStatusId === APPROVE_STATUS_ID.PENDING ||
                          item.ApproverStatusId === APPROVE_STATUS_ID.APPROVED ||
                          item.ApproverStatusId === APPROVE_STATUS_ID.REJECTED) ? (
                        <img
                          src={
                            item.ApproverStatusId === APPROVE_STATUS_ID.PENDING
                              ? Pending
                              : item.ApproverStatusId === APPROVE_STATUS_ID.APPROVED
                                ? Approved
                                : Rejected
                          }
                          alt=""
                          className={classes.caseStatusIcon}
                        />
                      ) : null}
                    </Box>
                  </TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.ProcedureTime) && (
                  <TableCellBody align="left">
                    {item.ProcedureTime}
                  </TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.PatientName) && (
                  <TableCellBody align="left">
                    {item.PatientFullName}
                  </TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.PatientDOB) && (
                  <TableCellBody>{item.PatientDOB}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.PrimarySurgeonName) && (
                  <TableCellBody>{item.PrimarySurgeonName}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.ProcedureDate) && (
                  <TableCellBody>{item.ProcedureDate}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.SurgicalLocationName) && (
                  <TableCellBody>{item.SurgicalLocationName}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.CaseId) && (
                  <TableCellBody>{item.CaseId}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.ProcedureUnitName) && (
                  <TableCellBody>{item.ProcedureUnitName}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.ConfirmationNumber) && (
                  <TableCellBody>{item.ConfirmationNumber}</TableCellBody>
                )}
                {showDashBoardCoulmn(MappingKeys.DocumentCount) && (
                  <TableCellBody>{item.DocumentCount}</TableCellBody>
                )}
              </TableRow>
              {item?.open && (
                <RenderCollapsableTable item={item} />
              )}
            </>
          ))}
        </>
      </>
    );
  };

  return (
    <>
      <CaseDialogBox
        open={Boolean(showDialog)}
        dialogTitle={""}
        dialogDescription={showDialog}
        onConfirm={() => handleDialogConfirm()}
      />
      <ApproveRejectDialog
        open={Boolean(showApproveRejectDialog)}
        dialogTitle={showApproveRejectDialog === RULE_STATUS_ACTION_APPROVE ? RULE_APPROVE_DIALOG_TITLE : RULE_REJECT_DIALOG_TITLE}
        confirmTitle={showApproveRejectDialog === RULE_STATUS_ACTION_APPROVE ? RULE_APPROVE_BTN_LBL : RULE_REJECT_BTN_LBL}
        onConfirm={(data) => handleConfirmApproveReject(data)}
        onCancel={() => { setshowApproveRejectDialog("") }}
      />
      <Paper
        elevation={2}
        sx={{ width: "100%", overflow: "hidden", marginBottom: 2 }}
      >
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCellHead align="center"></TableCellHead>
                {headCells &&
                  headCells.map(
                    (headCell) =>
                      showDashBoardCoulmn(headCell.Alias) && (
                        <TableCellHead align="left" sx={{ minWidth: `${headCell.width}px !important` }}>
                          <TableSortLabel
                            active={orderBy === headCell.Alias}
                            direction={
                              orderBy === headCell.Alias
                                ? order.toLowerCase()
                                : "asc"
                            }
                            onClick={() => handleColumnSort(headCell.Alias)}
                            classes={{
                              root: classes.sortLabelRoot,
                              icon: classes.sortLabelIcon,
                            }}
                          >
                            <Box className={classes.coulmnBox}>
                              {headCell.ColumnName}
                            </Box>
                          </TableSortLabel>
                        </TableCellHead>
                      )
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell component="th" scope="row" colSpan="9">
                    <Loader loading={loading} />
                  </TableCell>
                </TableRow>
              ) : Object.keys(tableData).length !== 0 ? (
                Object.entries(tableData).map(([key, value], i) => (
                  <RenderTableBody row={key} rowInfo={value} index={i} key={i} />
                ))
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row" colSpan="12">
                    {DASHBOARD_CASE_NOT_FOUND}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <DashboardTablePagination
          totalCases={totalData}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          onPageChange={(value) => handlePageChange(value)}
          onPerPageChange={(value) => handlePerPageChange(value)}
        />
      </Paper>
    </>
  );
};

export default DashboardTable;
