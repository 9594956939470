import {
  CLEAR_DASHBOARD_TO_INITIAL_STATE,
  UPDATE_CALENDAR_START_DATE_END_DATE,
  GET_CASES_SUCCESS,
  GET_CASES_FAIL,
  GET_DASHBOARD_FILTERS_SUCCESS,
  GET_DASHBOARD_FILTERS_FAIL,
  IS_DASHBOARD_BL,
  UPDATE_DASHBOARD_FILTER_VALUE,
  GET_MONTH_CASES_SUCCESS,
  GET_MONTH_CASES_FAIL,
  TOGGLE_TO_MONTH_VIEW,
  GET_CASES,
  UPDATE_DASHBOARD_CASE_LOADER,
  GET_MONTH_CASES,
  GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS,
  GET_DASHBOARD_PRIMARY_SURGEONS_FAIL,
  GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS,
  GET_DASHBOARD_SURGICAL_LOCATION_FAIL,
  UPDATE_DASHBOARD_START_DATE,
  UPDATE_DASHBOARD_END_DATE,
  GET_NON_COMPLAINT_GUIDELINES_SUCCESS,
  GET_NON_COMPLAINT_GUIDELINES_FAILURE,
  UPDATE_RULE_FAILURE
} from "../constants/ActionTypes";
import {
  DASHBOARD_ERROR_TYPES,
  DASHBOARD_NO_GUIDELINES_DIALOG_CONTENT_TEXT,
  SEARCH_FILTER_CONTROL_LIST,
  RULE_STATUS_ACTION_PENDING,
  RULE_STATUS_ACTION_APPROVE,
  RULE_STATUS_ACTION_REJECT
} from "../constants/GlobalConstants";
import dashboardService from "../services/DashboardService";
import { setSearchFiltersData } from "./ReportsAction";
import { notify } from "./SnackbarAction";

export function getDashboardCases(
  ItemPerPage,
  PageNumber,
  startDate,
  endDate,
  resetFilter = false,
  isFilter = false,
  caseStatusId = null,
  patientId = null,
  caseId = null,
  mrn = null,
  primarySurgeonId = null,
  surgicalLocationId = null,
  patientDOB = null,
  eventName = null
) {
  return async function (dispatch, getState) {
    try {
      dispatch(updateLoaderState(true));
      const state = getState();
      let requestData = null;
      requestData = getFilterRequestData(
        state,
        ItemPerPage,
        PageNumber,
        startDate,
        endDate,
        resetFilter,
        isFilter,
        caseStatusId,
        patientId,
        caseId,
        mrn,
        primarySurgeonId,
        surgicalLocationId,
        patientDOB,
        eventName
      );
      dashboardService
        .getCases(requestData)
        .then((response) => {
          var result = response ? response.responseData : [];
          dispatch(setSearchFiltersData([]));
          dispatch(getCasesSuccess(result));
          dispatch(
            updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy)
          );
        })
        .catch((err) => {
          dispatch(dashboardSearchBl(true));
          dispatch(getCasesFail(err));
        });
    } catch (err) {
      dispatch(getCasesFail(err));
    }
  };
}

export function getDashboardMonthCases(
  ItemPerPage,
  PageNumber,
  selected_start_date,
  selected_end_date,
  resetFilter = false,
  caseStatusId = null,
  patientId = null,
  caseId = null,
  mrn = null,
  primarySurgeonId = null,
  surgicalLocationId = null
) {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      let requestData = null;
      requestData = getFilterRequestData(
        state,
        ItemPerPage,
        PageNumber,
        selected_start_date,
        selected_end_date,
        resetFilter,
        caseStatusId,
        patientId,
        caseId,
        mrn,
        primarySurgeonId,
        surgicalLocationId
      );
      dashboardService.getMonthCases(requestData).then((response) => {
        var result = response ? response.responseData : [];
        dispatch(getMonthCasesSuccess(result));
        dispatch(updateLoaderState(false));
        dispatch(
          updateFilterResponse(SEARCH_FILTER_CONTROL_LIST, result.FilterBy)
        );
      });
    } catch (err) {
      dispatch(getCasesFail(err));
    }
  };
}

export function clearDashboardData() {
  return {
    type: CLEAR_DASHBOARD_TO_INITIAL_STATE,
  };
}

export function dashboardSearchBl(bl) {
  return {
    type: IS_DASHBOARD_BL,
    payload: bl,
  };
}

export function updateDashboardFilter(updatedDashboardFilter) {
  return {
    type: UPDATE_DASHBOARD_FILTER_VALUE,
    payload: updatedDashboardFilter,
  };
}

export function updateCalendarStartEndDate(dates) {
  return {
    type: UPDATE_CALENDAR_START_DATE_END_DATE,
    payload: dates,
  };
}

export function getCases(data) {
  return {
    type: GET_CASES,
    payload: data,
  };
}

export function updateLoaderState(data) {
  return {
    type: UPDATE_DASHBOARD_CASE_LOADER,
    loading: data,
  };
}

export function getCasesSuccess(data) {
  return {
    type: GET_CASES_SUCCESS,
    payload: data,
    loading: false,
  };
}

export function getCasesFail(data) {
  return {
    type: GET_CASES_FAIL,
    payload: data,
    loading: false,
  };
}

export function getMonthCases(data) {
  return {
    type: GET_MONTH_CASES,
    payload: data,
    loading: true,
  };
}

export function getMonthCasesSuccess(data) {
  return {
    type: GET_MONTH_CASES_SUCCESS,
    payload: data,
    loading: false,
    dashboardToggle: false,
  };
}

export function getMonthCasesFail(data) {
  return {
    type: GET_MONTH_CASES_FAIL,
    payload: data,
    loading: false,
  };
}

export function toggleToMonthView(data) {
  return {
    type: TOGGLE_TO_MONTH_VIEW,
    payload: data,
  };
}

export function getDashboardFilters() {
  return async function (dispatch, getState) {
    try {
      dashboardService
        .getDashboardFilters()
        .then((response) => {
          var result = response;
          result.responseData.fieldPermissions.map(
            (element) => (element.value = "")
          );

          dispatch(getDashboardFiltersSuccess(result.responseData));
        })
        .catch((err) => {
          dispatch(getDashboardFiltersFail(err));
        });
    } catch (err) {
      dispatch(getDashboardFiltersFail(err));
    }
  };
}

export function getDashboardFiltersSuccess(data) {
  return {
    type: GET_DASHBOARD_FILTERS_SUCCESS,
    payload: data,
  };
}

export function getDashboardFiltersFail(data) {
  return {
    type: GET_DASHBOARD_FILTERS_FAIL,
    payload: data,
  };
}

export function getDashboardPrimarySurgeons() {
  return async function (dispatch, getState) {
    dashboardService
      .getDashboardPrimarySurgeons()
      .then((data) => {
        dispatch(getDashboardPrimarySurgeonsSuccess(data));
      })
      .catch((err) => {
        dispatch(getDashboardPrimarySurgeonsFail(err));
      });
  };
}

export function getDashboardPrimarySurgeonsSuccess(data) {
  return {
    type: GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS,
    payload: data,
  };
}

export function getDashboardPrimarySurgeonsFail(data) {
  return {
    type: GET_DASHBOARD_PRIMARY_SURGEONS_FAIL,
    payload: data,
  };
}

export function getDashboardSurgicalLocations() {
  return async function (dispatch, getState) {
    dashboardService
      .getDashboardSurgicalLocations()
      .then((data) => {
        dispatch(getDashboardSurgicalLocationsSuccess(data));
      })
      .catch((err) => {
        dispatch(getDashboardSurgicalLocationsFail(err));
      });
  };
}

export function getDashboardSurgicalLocationsSuccess(data) {
  return {
    type: GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS,
    payload: data,
  };
}

export function getDashboardSurgicalLocationsFail(data) {
  return {
    type: GET_DASHBOARD_SURGICAL_LOCATION_FAIL,
    payload: data,
  };
}

export function updateDashBoardStartDate(data) {
  return {
    type: UPDATE_DASHBOARD_START_DATE,
    payload: data,
  };
}

export function updateDashBoardEndDate(data) {
  return {
    type: UPDATE_DASHBOARD_END_DATE,
    payload: data,
  };
}

const getFilterRequestData = (
  state,
  ItemPerPage,
  PageNumber,
  startDate,
  endDate,
  resetFilter,
  isFilter,
  caseStatusId = null,
  patientId = null,
  caseId = null,
  mrn = null,
  primarySurgeonId = null,
  surgicalLocationId = null,
  patientDOB = null,
  eventName = null
) => {
  let requestData = {};
  let searchData = state.reports.setFilterData.filter(
    (element) => element.Values.length > 0
  );
  let requestFilter = updateFieldMappings(
    SEARCH_FILTER_CONTROL_LIST,
    searchData
  );
  requestData.ItemPerPage = ItemPerPage;
  requestData.PageNumber = PageNumber;
  requestData.ProcedureStartDate = startDate;
  requestData.ProcedureEndDate = endDate;
  requestData.PatientId = patientId;
  requestData.CaseId = caseId;
  requestData.Mrn = mrn;
  requestData.PrimarySurgeonId = primarySurgeonId;
  requestData.Hospital = surgicalLocationId;
  requestData.CaseStatusId = caseStatusId;
  requestData.PatientDOB = patientDOB;
  requestData.SearchBl = eventName ? true : false;
  if (!eventName) {
    requestData.FilterBy = resetFilter
      ? []
      : isFilter && requestFilter.length > 0
        ? requestFilter
        : null;
  }
  return requestData;
};

const updateFieldMappings = (defaultFilter, getFilterValues) => {
  let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
  getFilterValues.map((element) => {
    let itemIndex =
      filtersValue &&
      filtersValue.findIndex((item) => item.displayId == element.Field);
    if (itemIndex >= 0) {
      element.Field = filtersValue[itemIndex].mappingId;
    }
  });
  return getFilterValues;
};

export function updateFilterResponse(defaultFilter, getFilterValues) {
  return async function (dispatch, getState) {
    let filtersValue = JSON.parse(JSON.stringify(defaultFilter));
    getFilterValues.map((element) => {
      let itemIndex =
        filtersValue &&
        filtersValue.findIndex((item) => item.mappingId == element.Field);
      if (itemIndex >= 0) {
        element.Field = filtersValue[itemIndex].displayId;
      }
    });
    dispatch(setSearchFiltersData(getFilterValues));
  };
}

//This `getNonCompliantGuidelines` function is used to call the API
export function getNonCompliantGuidelines(caseId, rowOpen, approverStatus = '') {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const cases = state.dashboard.casesData;

      const caseInfoRowOpen = (cases?.Items || []).map((info) => {
        if (info.Case?.CaseId == caseId) {
          return {
            ...info,
            Case: {
              ...(info.Case ?? {}),
              open: !rowOpen,
              nonCompliantGuidelinesLoading:
                !info.Case?.nonCompliantGuidelinesLoading,
            },
          };
        }
        return info;
      });
      // Update the case
      if (!rowOpen) {
        dashboardService
          .getNonCompliantGuidelinesByCaseId(caseId)
          .then((response) => {
            const result = response ? response.data?.payload?.caseRules : [];

            const permissionRules = response
              ? response.data?.payload?.fieldPermissions
              : [];

            const caseInfoRowOpen = (cases?.Items || []).map((info) => {
              if (info.Case?.CaseId == caseId) {
                return {
                  ...info,
                  Case: {
                    ...(info.Case ?? {}),
                    caseRules: result,
                    open: !rowOpen,
                    nonCompliantGuidelinesLoading: false,
                    fieldPermissions: permissionRules,
                  },
                };
              }
              return info;
            });

            let successPayload = {
              ...cases,
                Items: caseInfoRowOpen,
                permissionRules: permissionRules,
            }
            
            if(approverStatus !== ''){
              successPayload = updateCaseStateRuleStatusCount(successPayload, caseId, approverStatus)
            }

            dispatch(
              getNonCompliantGuidelinesSuccess(successPayload)
            );
          })
          .catch((err) => {
            const newCases = (cases?.Items || []).map((info) => {
              return {
                ...info,
                Case: {
                  ...(info.Case ?? {}),
                  open: false,
                  nonCompliantGuidelinesLoading: false,
                },
              };
            });
            dispatch(getNonCompliantGuidelinesFailure({
              error: err,
              data: {
                ...cases,
                Items: newCases,
              }
            }));
            if (err.type == DASHBOARD_ERROR_TYPES.NO_GUIDELINES) {
              dispatch(notify("Case", DASHBOARD_NO_GUIDELINES_DIALOG_CONTENT_TEXT, "warning"));
            }
          });
      } else {
        dispatch(
          getNonCompliantGuidelinesSuccess({
            ...cases,
            Items: caseInfoRowOpen,
          })
        );
      }
    } catch (err) {
      const newCases = (cases?.Items || []).map((info) => {
        return {
          ...info,
          Case: {
            ...(info.Case ?? {}),
            open: false,
            nonCompliantGuidelinesLoading: false,
          },
        };
      });
      dispatch(getNonCompliantGuidelinesFailure({
        error: err,
        data: {
          ...cases,
          Items: newCases,
        }
      }));
    }
  };
}

export function getNonCompliantGuidelinesSuccess(result) {
  return {
    type: GET_NON_COMPLAINT_GUIDELINES_SUCCESS,
    payload: result,
  };
}

export function getNonCompliantGuidelinesFailure(data) {
  return {
    type: GET_NON_COMPLAINT_GUIDELINES_FAILURE,
    payload: data,
  };
}

export function updateRuleFailure(data) {
  return {
    type: UPDATE_RULE_FAILURE,
    payload: data,
  };
}

export function updateApproveRejectRule(data) {
  return function updateApproveRejectRule(dispatch) {
    try {
      dashboardService.approveRejectRule(data)
        .then(response => {
          if (response.status == 200) {
            dispatch(getNonCompliantGuidelines(data.CaseId, false, response.data.payload.caseRuleStatus))
          } else {
            throw response
          }
        }).catch(error => {
          if (error.status == 403) {
            dispatch(updateRuleFailure({
              error: {
                type: DASHBOARD_ERROR_TYPES.UNAUTHORIZED_APPROVE_REJECT,
                data: error.data
              },
              data: {
                type: 'error',
                data: error.data
              }
            }));
            return;
          }
          dispatch(updateRuleFailure({
            error: {
              type: 'error',
              data: error.data
            },
            data: {
              type: 'error',
              data: error.data
            }
          }));
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export function updateCaseStateRuleStatusCount(caseData, caseId, approveStatus) {
  let updatedData = JSON.parse(JSON.stringify(caseData))
  let itemToUpdate = updatedData.Items.find(
    (item) => item.Case.CaseId == caseId
  );

  //Approver status Id
  let approveStatusId = null
  if(approveStatus == RULE_STATUS_ACTION_PENDING) {
    approveStatusId = 1
  } else if(approveStatus == RULE_STATUS_ACTION_APPROVE) {
    approveStatusId = 2
  } else if(approveStatus == RULE_STATUS_ACTION_REJECT) {
    approveStatusId = 3
  }

  if (!itemToUpdate) return updatedData;

  const currentApproveId = itemToUpdate.Case.ApproverStatusId;

  if(currentApproveId == approveStatusId) return updatedData;

  itemToUpdate.Case.ApproverStatusId = approveStatusId;

  if (currentApproveId === 1) {
    updatedData.CaseStatusesCount.PENDING_REVIEW -= 1;
    if (approveStatusId === 2) {
      updatedData.CaseStatusesCount.APPROVED += 1;
    } else if (approveStatusId === 3) {
      updatedData.CaseStatusesCount.REJECTED += 1;
    }
  } else if (currentApproveId === 2) {
    updatedData.CaseStatusesCount.APPROVED -= 1;
    if (approveStatusId === 1) {
      updatedData.CaseStatusesCount.PENDING_REVIEW += 1;
    } else if (approveStatusId === 3) {
      updatedData.CaseStatusesCount.REJECTED += 1;
    }
  } else if (currentApproveId === 3) {
    updatedData.CaseStatusesCount.REJECTED -= 1;
    if (approveStatusId === 1) {
      updatedData.CaseStatusesCount.PENDING_REVIEW += 1;
    } else if (approveStatusId === 2) {
      updatedData.CaseStatusesCount.APPROVED += 1;
    }
  }

  return updatedData;
}
