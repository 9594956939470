import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Paper,
  Box,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material';
import {
  getRuleResultsData,
  getRuleResultLoading,
  getGuidelineStatus,
  getRulePopUpOpen,
} from '../../reducers/CaseDetailsReducer';
import {
  reloadRuleResult,
  updateRuleNotification,
  updateGuidelineStatus,
  rulePopUpOpen,
} from '../../actions/CaseDetailsAction'
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  PermissionTypes,
  RULE_APPROVE_BTN_MAPPING_ID,
  RULE_REJECT_BTN_MAPPING_ID,
  LBL_RECORDS_NOT_FOUND,
  RULE_NAME_MAPPING_ID,
  RULE_PARAMS_MAPPING_ID,
  RULE_RECOMMENDATION_MAPPING_ID,
  RULE_MESSAGE_MAPPING_ID,
  RULE_STATUS_MAPPING_ID,
  RULE_COMMENTS_MAPPING_ID,
  RULE_ACTIONS_MAPPING_ID,
  DASHBOARD_UNAUTHORIZED_DIALOG_CONTENT_TEXT,
  DASHBOARD_NO_GUIDELINES_DIALOG_CONTENT_TEXT,
  RULE_STATUS_ACTION_APPROVE,
  RULE_STATUS_ACTION_REJECT,
  RULE_REJECT_BTN_LBL,
  RULE_APPROVE_BTN_LBL,
  RULE_APPROVE_DIALOG_TITLE,
  RULE_REJECT_DIALOG_TITLE,
  RELOAD_RULE_RESULT_TITLE,
  RELOAD_RULE_RESULT_MESSAGE,
  DASHBOARD_ERROR_TYPES,
  NO_ACTIVE_GUIDELINES_MSG,
  NO_GUIDELINE_TITLE,
  COMMON_DATE_FORMAT,
  COMMON_TIME_FORMAT,
} from '../../constants/GlobalConstants';
import { useParams } from "react-router-dom"
import ApproveRejectDialog from '../dashboard/ApproveRejectDialog';
import CaseDialogBox from "../case/CaseDialogBox";
import * as Colors from '../../res/values/Colors';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  approveBtn: {
    minWidth: 100
  },
  ruleLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ruleTableHeader: {
    background: Colors.AMENDMENTS_LIST_HEADER
  }
}))

const ruleTableCoulmn = [
  { id: RULE_NAME_MAPPING_ID, label: 'Rule', width: 50 },
  { id: RULE_PARAMS_MAPPING_ID, label: 'Case Details', width: 80 },
  { id: RULE_RECOMMENDATION_MAPPING_ID, label: ' Guideline', width: 50 },
  { id: RULE_MESSAGE_MAPPING_ID, label: 'Message', width: 100 },
  { id: RULE_STATUS_MAPPING_ID, label: 'Review Status', width: 100 },
  { id: RULE_COMMENTS_MAPPING_ID, label: 'Comments', width: 100 },
  { id: RULE_ACTIONS_MAPPING_ID, label: 'Actions', width: 50 },
]

//Initial state for rule approval/rejection
const ruleStatusPayload = {
  CaseRuleResultId: null,
  Action: "",
  RuleComments: "",
  CaseId: null
}

const RuleResultTable = (props) => {
  const ruleResultInfo = useSelector(getRuleResultsData)
  const ruleResultLoading = useSelector(getRuleResultLoading)
  const guideLineStatus = useSelector(getGuidelineStatus);
  const isRulePopUpOpen = useSelector(getRulePopUpOpen);
  const classes = useStyles()
  const [showApproveRejectDialog, setShowApproveRejectDialog] = useState("");
  const [ruleStatusRequestData, setRuleStatusRequestData] = useState(ruleStatusPayload)
  const [ruleStatusAction, setRuleStatusAction] = useState(false)
  let { caseId } = useParams();
  const [showDialog, setshowDialog] = useState(0);
  const dispatch = useDispatch()

  const prevGuideLineStatusCount = useRef(guideLineStatus.count);
  const prevGuideLineStatusStatus = useRef(guideLineStatus.status);

  // this function  is used to handle dailog box unauthorized
  const handleDialogConfirm = () => {
    setshowDialog("");
    if (dashboardError?.type === DASHBOARD_ERROR_TYPES.UNAUTHORIZED || dashboardError?.type === DASHBOARD_ERROR_TYPES.NO_GUIDELINES) {
      const dashboardStartDate = sessionStorage.getItem("dashboardStartDate")
      const dashboardEndDate = sessionStorage.getItem("dashboardEndDate")
      var startDate = dashboardStartDate ? moment(dashboardStartDate).format(DASHBOARD_DATE_FORMAT) : moment(new Date()).format(DASHBOARD_DATE_FORMAT);
      var endDate = dashboardEndDate ? moment(dashboardEndDate).format(DASHBOARD_DATE_FORMAT) : moment(new Date()).format(DASHBOARD_DATE_FORMAT);
      dispatch(
        getDashboardCases(
          DASHBOARD_DEFAULT_ITEM_PER_PAGE,
          DASHBOARD_DEFAULT_PAGE_NUMBER,
          startDate,
          endDate
        )
      );
    }
  };

  useEffect(() => {
    // Compare the current and previous values
    const hasCountChanged = prevGuideLineStatusCount.current !== guideLineStatus.count;
    const hasStatusChanged = prevGuideLineStatusStatus.current !== guideLineStatus.status;

    // Only update the state if either count or status has changed
    if (hasCountChanged || hasStatusChanged) {
      // Update previous values with the current ones
      prevGuideLineStatusCount.current = guideLineStatus.count;
      prevGuideLineStatusStatus.current = guideLineStatus.status;

      if (!ruleStatusAction && isRulePopUpOpen && ruleResultInfo && ruleResultInfo?.caseRules?.length > 0 && guideLineStatus.count > 0) {
        const notificationPayload = {
          status: true,
          title: RELOAD_RULE_RESULT_TITLE,
          message: RELOAD_RULE_RESULT_MESSAGE,
        };
        dispatch(reloadRuleResult(true));
        dispatch(updateRuleNotification(notificationPayload));

      } else if (isRulePopUpOpen && guideLineStatus.count == 0 && guideLineStatus.status == "") {
        const noGuidelinesNotificationPayload = {
          status: true,
          title: NO_GUIDELINE_TITLE,
          message: NO_ACTIVE_GUIDELINES_MSG,
        };
        dispatch(updateRuleNotification(noGuidelinesNotificationPayload));
        dispatch(rulePopUpOpen(false))
      }

      setRuleStatusAction(false)
    }
  }, [guideLineStatus.count, guideLineStatus.status]);

  //To show/hide table column as per permission type
  const showRuleColumn = (mappingId) => {
    let showColumn = false
    if (mappingId == "Actions") {
      const showApprove = ruleResultInfo?.fieldPermissions?.some(item => (
        item.fieldMappingId == RULE_APPROVE_BTN_MAPPING_ID && (
          item.fieldPermissionTypeId == PermissionTypes.ENABLE_FIELD || item.fieldPermissionTypeId == PermissionTypes.DISABLE_FIELD
        )))
      const showReject = ruleResultInfo?.fieldPermissions?.some(item => (
        item.fieldMappingId == RULE_REJECT_BTN_MAPPING_ID && (
          item.fieldPermissionTypeId == PermissionTypes.ENABLE_FIELD || item.fieldPermissionTypeId == PermissionTypes.DISABLE_FIELD
        )))

      if (showApprove || showReject) {
        showColumn = true
      }
    } else {
      showColumn = ruleResultInfo?.fieldPermissions?.some(item => item.fieldMappingId == mappingId)
    }
    return showColumn
  }

  //To format rule params value
  const serializeAndFormat = (input) => {
    const parsedObject = JSON.parse(input);

    const defaultKeys = {
      cptCode: "CPT",
      icdCode: "ICD",
    };
    const formatKey = (key) => {
      if (defaultKeys[key]) return defaultKeys[key];
      return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^[a-z]/, (char) => char.toUpperCase());
    };

    return Object.entries(parsedObject)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => <Box>{`${formatKey(key)} : ${value}`}</Box>);
  }

  //To enable/disable actions used in table
  const disableActions = (mappingId) => {
    const disableItem = ruleResultInfo?.fieldPermissions?.some(item => (
      item.fieldMappingId == mappingId && item.fieldPermissionTypeId == PermissionTypes.DISABLE_FIELD
    ))
    return disableItem
  }

  //To show approve reject dialog box
  const showRuleApproveRejectDialog = (action, ruleResultId) => {
    setShowApproveRejectDialog(action)
    setRuleStatusRequestData({
      ...ruleStatusRequestData,
      CaseId: parseInt(caseId),
      Action: action,
      CaseRuleResultId: ruleResultId
    })
  }

  //To approve/reject a guideline
  const handleConfirmApproveReject = (comment) => {
    const requestPayload = {
      ...ruleStatusRequestData,
      RuleComments: comment
    }
    dispatch(updateGuidelineStatus(requestPayload))
    setRuleStatusRequestData(ruleStatusPayload)
    setShowApproveRejectDialog("")
    setRuleStatusAction(true)
  }

  const RuleTableRow = (props) => {
    const { data } = props

    return (
      <>
        {data?.caseRules?.length > 0 ? data.caseRules.map(item => (
          <TableRow key={item.caseRuleResultId}>
            {showRuleColumn(RULE_NAME_MAPPING_ID) && (
              <TableCell>{item.ruleName}</TableCell>
            )}
            {showRuleColumn(RULE_PARAMS_MAPPING_ID) && (
              <TableCell><Stack spacing={1}>{serializeAndFormat(item.ruleParameters)}</Stack></TableCell>
            )}
            {showRuleColumn(RULE_RECOMMENDATION_MAPPING_ID) && (
              <TableCell>{item.ruleRecommendation}</TableCell>
            )}
            {showRuleColumn(RULE_MESSAGE_MAPPING_ID) && (
              <TableCell>{item.ruleMessage}</TableCell>
            )}
            {showRuleColumn(RULE_STATUS_MAPPING_ID) && (
              <TableCell>
                <Stack spacing={1}>
                  <Box>{item.approverStatus}</Box>
                  {item.approvedBy && (<>
                    <Box>{item.approvedBy}</Box>
                    <Box>{moment(item.approvedOn).format(COMMON_DATE_FORMAT)}</Box>
                    <Box>{moment(item.approvedOn).format(COMMON_TIME_FORMAT)}</Box>
                  </>
                  )}
                </Stack>
              </TableCell>
            )}
            {showRuleColumn(RULE_COMMENTS_MAPPING_ID) && (
              <TableCell>{item.approverComments}</TableCell>
            )}
            {showRuleColumn(RULE_ACTIONS_MAPPING_ID) && (
              <TableCell>
                <Stack spacing={1}>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={disableActions(RULE_APPROVE_BTN_MAPPING_ID)}
                    className={classes.approveBtn}
                    onClick={() => showRuleApproveRejectDialog(RULE_STATUS_ACTION_APPROVE, item.caseRuleResultId)}
                  >{RULE_APPROVE_BTN_LBL}</Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={disableActions(RULE_REJECT_BTN_MAPPING_ID)}
                    className={classes.approveBtn}
                    onClick={() => showRuleApproveRejectDialog(RULE_STATUS_ACTION_REJECT, item.caseRuleResultId)}
                  >{RULE_REJECT_BTN_LBL}</Button>
                </Stack>
              </TableCell>
            )}

          </TableRow>
        )) : (
          <TableRow>
            <TableCell>{LBL_RECORDS_NOT_FOUND}</TableCell>
          </TableRow>
        )}

      </>
    )
  }

  return (
    <> <CaseDialogBox
      open={Boolean(showDialog)}
      dialogTitle={""}
      dialogDescription={showDialog == "unauthorized"
        ? DASHBOARD_UNAUTHORIZED_DIALOG_CONTENT_TEXT
        : DASHBOARD_NO_GUIDELINES_DIALOG_CONTENT_TEXT}
      onConfirm={() => handleDialogConfirm()}
    />
      <ApproveRejectDialog
        open={Boolean(showApproveRejectDialog)}
        dialogTitle={showApproveRejectDialog === RULE_STATUS_ACTION_APPROVE ? RULE_APPROVE_DIALOG_TITLE : RULE_REJECT_DIALOG_TITLE}
        confirmTitle={showApproveRejectDialog === RULE_STATUS_ACTION_APPROVE ? RULE_APPROVE_BTN_LBL : RULE_REJECT_BTN_LBL}
        onConfirm={(data) => handleConfirmApproveReject(data)}
        onCancel={() => setShowApproveRejectDialog("")}
      />

      {ruleResultLoading ? (
        <Box className={classes.ruleLoader}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.ruleTableHeader}>
                <TableRow>
                  {ruleTableCoulmn.map((column) => showRuleColumn(column.id) && (

                    <TableCell
                      key={column.id}
                      sx={{ minWidth: `${column.width}px !important` }}
                      className={classes.ruleTableHeader}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <RuleTableRow data={ruleResultInfo} />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};

export default RuleResultTable;