import React, { useEffect, useState } from 'react';
import DataTable from '../controls/DataTable';
import { connect, useDispatch, useSelector } from "react-redux";
import DashboardFieldComponent from '../common/DashboardFieldComponent';
import { Grid, Stack } from '@mui/material';
import { dashboardFilterFields, dashboardToggle, startDate, endDate } from '../../reducers/DashboardReducer';
import FilterControls from '../common/FilterControls';
import { setSearchFiltersData } from '../../actions/ReportsAction';
import { getFilterFiledValue, getLoadingStatus } from '../../reducers/ReportsReducer';
import { SearchByFilters, getDashboardCases, getDashboardMonthCases } from '../../actions/DashboardAction';
import moment from 'moment';
import {
    APPLY_FILTER_LABEL,
    DASHBOARD_DEFAULT_ITEM_PER_PAGE,
    DASHBOARD_DEFAULT_PAGE_NUMBER,
    DASHBOARD_FILTER_CONTROLS,
    RESET_FILTER_LABEL,
    DASHBOARD_DATE_FORMAT
} from '../../constants/GlobalConstants';

const dashBoardSearch = [
    {
        key: "DasPatientName",
        fieldType: "text",
        isRequired: true,
        control: {
            fieldId: "DasPatientName",
            fieldTypeName: "text",
            value: "",
            defaultFieldName: "Patient Name"
        }
    }, {
        key: "DasSurgeonName",
        fieldType: "selectionkeyvalue",
        isRequired: true,
        control: {
            fieldId: "DasSurgeonName",
            options: ["One", "Two", "Three"],
            fieldTypeName: "selectionkeyvalue",
            value: "",
            defaultFieldName: "Surgeon Name"
        }
    }, {
        key: "DasPatientDOB",
        fieldType: "date",
        isRequired: false,
        control: {
            fieldId: "DasPatientDOB",
            fieldTypeName: "date",
            defaultFieldName: "Patient DOB",
            format: "MM/dd/yyyy",
            margin: "normal",
            label: "Patient DOB",
            variant: "inline",
            value: "08/05/2021"
        }
    }, {
        key: "DasSchedulerName",
        fieldType: "text",
        isRequired: true,
        control: {
            fieldId: "DasSchedulerName",
            fieldTypeName: "text",
            value: "",
            defaultFieldName: "Scheduler Name"
        }
    }, {
        key: "DasCaseID",
        fieldType: "text",
        isRequired: true,
        control: {
            fieldId: "DasCaseID",
            fieldTypeName: "text",
            value: "",
            defaultFieldName: "Case ID"
        }
    }]
function ContentPage({ dashboardFieldSearch }) {
    const loading = useSelector(getLoadingStatus);
    const monthViewToggle = useSelector(dashboardToggle);
    const getSearchFilter = useSelector(getFilterFiledValue);
    const calendarDate = useSelector(startDate);
    const dispatch = useDispatch();
    const dashboardStartDate = sessionStorage.getItem("dashboardStartDate")
    const dashboardEndDate = sessionStorage.getItem("dashboardEndDate")
    const selectedMonth = sessionStorage.getItem("selectedMonth")

    const getDahboardfilterData = (resetFilter, isFilter) => {
        const selectedStartMonth = selectedMonth ? moment(selectedMonth).startOf('month').format(DASHBOARD_DATE_FORMAT) : moment(calendarDate).startOf('month').format(DASHBOARD_DATE_FORMAT);
        const selectedEndMonth = selectedMonth ? moment(selectedMonth).endOf('month').format(DASHBOARD_DATE_FORMAT) : moment(calendarDate).endOf('month').format(DASHBOARD_DATE_FORMAT);
        const selectedStartDate = dashboardStartDate ? moment(dashboardStartDate).format(DASHBOARD_DATE_FORMAT) : moment(calendarDate).format(DASHBOARD_DATE_FORMAT);
        const selectedEndDate = dashboardEndDate ? moment(dashboardEndDate).format(DASHBOARD_DATE_FORMAT) : moment(calendarDate).format(DASHBOARD_DATE_FORMAT);
        if (monthViewToggle) {
            dispatch(getDashboardMonthCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selectedStartMonth, selectedEndMonth, resetFilter, isFilter));

        } else {
            dispatch(getDashboardCases(DASHBOARD_DEFAULT_ITEM_PER_PAGE, DASHBOARD_DEFAULT_PAGE_NUMBER, selectedStartDate, selectedEndDate, resetFilter, isFilter));
        }
    }

    const ApplyFilter = () => {
        let resetFilter = false;
        if (getSearchFilter) {
            let searchData = getSearchFilter.filter(element => element.Values.length > 0);
            if (searchData.length == 0) {
                resetFilter = true;
            }
        }
        getDahboardfilterData(resetFilter, true);
    }
    const ResetFilter = () => {
        dispatch(setSearchFiltersData([]));
        let resetFilter = true;
        getDahboardfilterData(resetFilter, false);
    }
    return (
        <div>
            <Grid constainer item xs={12}>
                <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <FilterControls
                        loading={loading}
                        filterMd={6}
                        filterLg={3}
                        displayFilters={DASHBOARD_FILTER_CONTROLS}
                        applyLabel={APPLY_FILTER_LABEL}
                        resetLabel={RESET_FILTER_LABEL}
                        applyFilter={(e) => ApplyFilter()}
                        resetFilter={(e) => ResetFilter()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataTable />
                </Grid>
            </Grid>

        </div>
    )
}
const mapStateToProps = state => ({
    //dashboardFieldSearch: dashboardFilterFields(state)
});
export default connect(mapStateToProps, null)(ContentPage);
