//User Reducer
export const LOGIN_MULTITENANTS = 'LOGIN_MULTITENANTS'
export const LOGIN_SINGLETENANT = 'LOGIN_SINGLETENANT'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const RESET_COMPONENT = 'RESET_COMPONENT'
export const SAVE_SELECTED_TENANT = 'SAVE_SELECTED_TENANT'
export const EXPIRE_TOKEN_STATUS = "EXPIRE_TOKEN_STATUS"

//Snackbar Reducer
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

//Notification Reducer
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED'
export const GET_BOOKING_SHEET_SUCCESS = 'GET_BOOKING_SHEET_SUCCESS'
export const GET_BOOKING_SHEET_FAILURE = 'GET_BOOKING_SHEET_FAILURE'
export const UPDATE_CONTROL_VALUE = 'UPDATE_CONTROL_VALUE'
export const GET_SECTION_DETAILS_SUCCESS = 'GET_SECTION_DETAILS_SUCCESS'
export const GET_SECTION_DETAILS_FAILURE = 'GET_SECTION_DETAILS_FAILURE'
export const UPDATE_SECTION_GROUP_LOADER_STATUS = 'UPDATE_SECTION_GROUP_LOADER_STATUS'
export const UPDATE_POPUP_LOADER_STATUS = 'UPDATE_POPUP_LOADER_STATUS'
export const UPDATE_NAVIGATION_LOADER_STATUS = 'UPDATE_NAVIGATION_LOADER_STATUS'
export const UPDATE_DASHBOARD_CASE_LOADER = 'UPDATE_DASHBOARD_CASE_LOADER'
export const DISABLE_NAVIGATION_SECTION = 'DISABLE_NAVIGATION_SECTION'
export const GET_SELECTIONS_FOR_CONTROL_SUCCESS = 'GET_SELECTIONS_FOR_CONTROL_SUCCESS'
export const GET_SELECTIONS_FOR_CONTROL_FAILURE = 'GET_SELECTIONS_FOR_CONTROL_FAILURE'
export const CONTROL_SELECTION_UPDATE_SUCCESS = "CONTROL_SELECTION_UPDATE_SUCCESS"
export const CONTROL_SELECTION_UPDATE_FAILURE = "CONTROL_SELECTION_UPDATE_FAILURE"
export const SAVE_SECTION_DETAILS_SUCCESS = "SAVE_SECTION_DETAILS_SUCCESS"
export const SAVE_SECTION_DETAILS_FAILURE = "SAVE_SECTION_DETAILS_FAILURE"
export const SAVE_SECTION_DETAILS_FAILURE_RESET_COUNT = "SAVE_SECTION_DETAILS_FAILURE_RESET_COUNT"
export const SHOW_FORM_CONTROL = 'SHOW_FORM_CONTROL'
export const HIDE_FORM_CONTROL = 'HIDE_FORM_CONTROL'
export const SHOW_SECTION_GROUP = 'SHOW_SECTION_GROUP'
export const HIDE_SECTION_GROUP = 'HIDE_SECTION_GROUP'
export const CLEAR_FIELD_VALUE = 'CLEAR_FIELD_VALUE'
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS'
export const CREATE_CASE_FAILURE = 'CREATE_CASE_FAILURE'
export const CREATE_CASE_FAILURE_RESET = 'CREATE_CASE_FAILURE_RESET'
export const PUSH_TO_TARGET = 'PUSH_TO_TARGET'
export const DELETE_FROM_TARGET = 'DELETE_FROM_TARGET'
export const UPDATE_SECTION_STATUS = 'UPDATE_SECTION_STATUS'
export const UPDATE_SECTION_ID = 'UPDATE_SECTION_ID'
export const UPDATE_SECTION_NAME = 'UPDATE_SECTION_NAME'
export const UPDATE_BOOKING_SHEET_SECTION_LIST = 'UPDATE_BOOKING_SHEET_SECTION_LIST'
export const UPDATE_IS_REQUIRED_VALIDATION = 'UPDATE_IS_REQUIRED_VALIDATION'
export const UPDATE_ASYNC_LOAD_FLAG = 'UPDATE_ASYNC_LOAD_FLAG'
export const CONTROL_SELECTION_RELOAD_SUCCESS = 'CONTROL_SELECTION_RELOAD_SUCCESS'
export const ADD_DYNAMIC_FIELD_SUCCESS = 'ADD_DYNAMIC_FIELD_SUCCESS'
export const UPDATE_ADDITIONAL_PROPS = 'UPDATE_ADDITIONAL_PROPS'
export const UPDATE_ADDITIONAL_PROPS_IN_POPUP = 'UPDATE_ADDITIONAL_PROPS_IN_POPUP'
export const GET_SINGLE_SECTION_DATA = 'GET_SINGLE_SECTION_DATA'
export const EXECUTE_SERVICE_API = 'EXECUTE_SERVICE_API'
export const COPY_REFERENCE_DATA = 'COPY_REFERENCE_DATA'
export const RELOAD_AND_COPY_REFERENCE_DATA = 'RELOAD_AND_COPY_REFERENCE_DATA'
export const OVERRIDE_VALIDATION_ENGINE = 'OVERRIDE_VALIDATION_ENGINE'
export const UPDATE_CONTROL_VAL_IN_POPUP = 'UPDATE_CONTROL_VAL_IN_POPUP'
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG'
export const SESSION_WARNING_POPUP = 'SESSION_WARNING_POPUP'
export const UPDATE_CALENDAR_START_DATE_END_DATE = 'UPDATE_CALENDAR_START_DATE_END_DATE'
export const CLEAR_DASHBOARD_TO_INITIAL_STATE = 'CLEAR_DASHBOARD_TO_INITIAL_STATE'
export const GET_CASES = 'GET_CASES'
export const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS'
export const GET_CASES_FAIL = 'GET_CASES_FAIL'
export const GET_MONTH_CASES = 'GET_MONTH_CASES'
export const GET_MONTH_CASES_SUCCESS = 'GET_MONTH_CASES_SUCCESS'
export const GET_MONTH_CASES_FAIL = 'GET_MONTH_CASES_FAIL'
export const TOGGLE_TO_MONTH_VIEW = 'TOGGLE_TO_MONTH_VIEW'
export const UPDATE_ACTION_BUTON_STATUS = 'UPDATE_ACTION_BUTON_STATUS'
export const UPDATE_BOOKINGSHEET_FORM_DATA = 'UPDATE_BOOKINGSHEET_FORM_DATA'
export const GET_DASHBOARD_FILTERS_SUCCESS = 'GET_DASHBOARD_FILTERS_SUCCESS'
export const GET_DASHBOARD_FILTERS_FAIL = 'GET_DASHBOARD_FILTERS_FAIL'
export const GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS = 'GET_DASHBOARD_PRIMARY_SURGEONS_SUCCESS'
export const GET_DASHBOARD_PRIMARY_SURGEONS_FAIL = 'GET_DASHBOARD_PRIMARY_SURGEONS_FAIL'
export const GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS = 'GET_DASHBOARD_SURGICAL_LOCATION_SUCCESS'
export const GET_DASHBOARD_SURGICAL_LOCATION_FAIL = 'GET_DASHBOARD_SURGICAL_LOCATION_FAIL'
export const IS_DASHBOARD_BL = 'IS_DASHBOARD_BL'
export const UPDATE_DASHBOARD_FILTER_VALUE = 'UPDATE_DASHBOARD_FILTER_VALUE'
export const CLEAR_CASE_DETAILS = 'CLEAR_CASE_DETAILS'
export const UPDATE_CASE_AMENDMENT_BL_STATUS = 'UPDATE_CASE_AMENDMENT_BL_STATUS'
export const UPDATE_CASE_VIEW = 'UPDATE_CASE_VIEW'
export const UPDATE_CASE_ID = 'UPDATE_CASE_ID'
export const IS_CREATE_CASE = 'IS_CREATE_CASE'
export const XCONTROL_SECTION_ACTION = 'XCONTROL_SECTION_ACTION'
export const SET_CREATE_CASE = 'SET_CREATE_CASE'
export const POPUP_CONTROL_SELECTION_UPDATE_SUCCESS = 'POPUP_CONTROL_SELECTION_UPDATE_SUCCESS'
export const GET_VIEW_DOCUMENT_DATA_SUCCESS = 'GET_VIEW_DOCUMENT_DATA_SUCCESS'
export const GET_VIEW_DOCUMENT_DATA_FAILURE = 'GET_VIEW_DOCUMENT_DATA_FAILURE'
export const GET_VIEW_DOCUMENT_FILESTREAM_SUCCESS = 'GET_VIEW_DOCUMENT_FILESTREAM_SUCCESS'
export const GET_VIEW_DOCUMENT_FILESTREAM_FAILURE = 'GET_VIEW_DOCUMENT_FILESTREAM_FAILURE'
export const DISPLAY_PATIENT_MAPPING_MESSAGE = 'DISPLAY_PATIENT_MAPPING_MESSAGE'
export const GET_NON_COMPLAINT_GUIDELINES_SUCCESS = 'GET_NON_COMPLAINT_GUIDELINES_SUCCESS';
export const GET_NON_COMPLAINT_GUIDELINES_FAILURE = 'GET_NON_COMPLAINT_GUIDELINES_FAILURE';
export const UPDATE_RULE_FAILURE = 'UPDATE_RULE_FAILURE';

export const CLEAR_POPUP_STORE_DATA='CLEAR_POPUP_STORE_DATA'
export const UPDATE_SIGNAL_R_LOCK_STATUS='UPDATE_SIGNAL_R_LOCK_STATUS'
export const DESTROY_SESSION='DESTROY_SESSION'
export const UPDATE_SIGNAL_R_HUB_CONNECTION='UPDATE_SIGNAL_R_HUB_CONNECTION'
export const UPDATE_IF_USER_ID_AUTH = 'UPDATE_IF_USER_ID_AUTH'
export const UPDATE_IS_POPUP_OPEN = 'UPDATE_IS_POPUP_OPEN'
export const UPDATE_CLIECKED_FIELD_ID = 'UPDATE_CLIECKED_FIELD_ID'
export const UPDATE_ACTION_BUTTONS_PROPERTIES = 'UPDATE_ACTION_BUTTONS_PROPERTIES'
export const UPDATE_SELECTED_INSURANCE_DEPENDENT_FIELD = 'UPDATE_SELECTED_INSURANCE_DEPENDENT_FIELD'
export const GET_CASE_SUMMARY_SUCCESS = 'GET_CASE_SUMMARY_SUCCESS'
export const GET_CASE_SUMMARY_FAILURE = 'GET_CASE_SUMMARY_FAILURE'
export const SAVE_VERIFY_INSURANCE_SUCCESS = 'SAVE_VERIFY_INSURANCE_SUCCESS'
export const SAVE_VERIFY_INSURANCE_FAILURE = 'SAVE_VERIFY_INSURANCE_FAILURE'
export const CONTROL_SEARCHED_SELECTION_UPDATE_SUCCESS = 'CONTROL_SEARCHED_SELECTION_UPDATE_SUCCESS'
export const SELECTION_LOADER_STATUS_UPDATE = 'SELECTION_LOADER_STATUS_UPDATE'
export const UPDATE_SECTION_COMPLETION_STATUS = 'UPDATE_SECTION_COMPLETION_STATUS'
export const UPDATE_DOC_SECTION_DETAILS = 'UPDATE_DOC_SECTION_DETAILS'
export const UPDATE_DOC_POPUP_DETAILS = 'UPDATE_DOC_POPUP_DETAILS'
export const UPDATE_DOC_POPUP_SELECTION_CONTROLS = 'UPDATE_DOC_POPUP_SELECTION_CONTROLS'
export const UPDATE_DOC_POP_UP_OPEN = 'UPDATE_DOC_POP_UP_OPEN'
export const UPDATE_DOC_POP_UP_SECTION_LOAD = 'UPDATE_DOC_POP_UP_SECTION_LOAD'
export const UPDATE_DOC_PREVIEW_STATUS = 'UPDATE_DOC_PREVIEW_STATUS'

//SectionAlertReducer
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

//Reports
export const GET_REPORT_CASES = 'GET_REPORT_CASES';
export const GET_REPORT_CASES_SUCCESS = 'GET_REPORT_CASES_SUCCESS';
export const GET_REPORT_CASES_FAILURE = 'GET_REPORT_CASES_FAILURE';
export const UPDATE_REPORT_CASES = 'UPDATE_REPORT_CASES';
export const UPDATE_REPORT_CASES_SUCCESS = 'UPDATE_REPORT_CASES_SUCCESS';
export const UPDATE_REPORT_CASES_FAILURE = 'UPDATE_REPORT_CASES_FAILURE';
export const SET_REPORT_CASES_COUNT = 'SET_REPORT_CASES_COUNT';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_ITEMS_PER_PAGE = 'UPDATE_ITEMS_PER_PAGE';
export const SET_ACTIVE_STATUS = 'SET_ACTIVE_STATUS';
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
export const UPDATE_REPORT_SORT_ITEMS='UPDATE_REPORT_SORT_ITEMS';
export const SET_FILTERS_REFERENCE_DATA = 'SET_FILTERS_REFERENCE_DATA';
export const SET_FILTERS_DATA = 'SET_FILTERS_DATA';
export const SAVE_ACKNOWLEDGEMENT_LOADING = 'SAVE_ACKNOWLEDGEMENT_LOADING';
export const RESET_PAGINATION_OPTIONS = 'RESET_PAGINATION_OPTIONS';
export const RESET_REPORTS_CASE_COUNT = 'RESET_REPORTS_CASE_COUNT';
export const GET_CASE_AMENDMENT_SUCCESS = 'GET_CASE_AMENDMENT_SUCCESS';
export const GET_CASE_AMENDMENT_FAILURE = 'GET_CASE_AMENDMENT_FAILURE';
export const SET_ALREADY_ACKNOWLEDGE_EMR = 'SET_ALREADY_ACKNOWLEDGE_EMR';
export const PENDING_VENDOR_CONF_SUCCESS= 'PENDING_VENDOR_CONF_SUCCESS';
export const UPDATE_WORK_QUEUE_LIST_DATA= 'UPDATE_WORK_QUEUE_LIST_DATA';

//Userprofile
export const GET_USER_NOTIFICATION_PREFERENCES_SUCCESS = 'GET_USER_NOTIFICATION_PREFERENCES_SUCCESS';
export const GET_USER_NOTIFICATION_PREFERENCES_FAILURE = 'GET_USER_NOTIFICATION_PREFERENCES_FAILURE';
export const UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_USER_NOTIFICATION_PREFERENCES_SUCCESS';
export const ACTION_BUTTON_LOADER_STATUS = ' ACTION_BUTTON_LOADER_STATUS';
export const UPDATE_HEADER_INFORMATION = 'UPDATE_HEADER_INFORMATION';
export const UPDATE_CASE_EDITABLE_MODE = 'UPDATE_CASE_EDITABLE_MODE';
export const NAVIGATION_SOURCE = 'NAVIGATION_SOURCE';

//Data Upload Page Actions
export const GET_DATAUPLOAD_ORG_TENANTS_SUCCESS = 'GET_DATAUPLOAD_ORG_TENANTS_SUCCESS'
export const GET_DATAUPLOAD_ORG_TENANTS_FAIL = 'GET_DATAUPLOAD_ORG_TENANTS_FAIL'
export const GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS = 'GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS'
export const GET_ADMIN_DATAUPLOAD_TYPES_FAIL = 'GET_ADMIN_DATAUPLOAD_TYPES_FAIL'
export const SET_INITIAL_STAGE = 'SET_INITIAL_STAGE'
export const UPDATE_ADMIN_FILE_UPLOAD_LOADER = 'UPDATE_ADMIN_FILE_UPLOAD_LOADER'
export const UPLOAD_ADMIN_DATA_SUCCESS = 'UPLOAD_ADMIN_DATA_SUCCESS'
export const UPLOAD_ADMIN_DATA_FAIL = 'UPLOAD_ADMIN_DATA_FAIL'
export const TOGGLE_PANEL = 'TOGGLE_PANEL'
export const SET_ERROR = 'SET_ERROR'
export const GET_MAIN_RUN_DATA_SUCCESS = 'GET_MAIN_RUN_DATA_SUCCESS'
export const GET_MAIN_RUN_DATA_FAIL = 'GET_MAIN_RUN_DATA_FAIL'
export const UPDATE_DOCUMENT_ALERT_POPUP_STATUS = 'UPDATE_DOCUMENT_ALERT_POPUP_STATUS'

//Dashboard
export const UPDATE_DASHBOARD_START_DATE = 'UPDATE_DASHBOARD_START_DATE'
export const UPDATE_DASHBOARD_END_DATE = 'UPDATE_DASHBOARD_END_DATE'

//Rule engine
export const UPDATE_RULE_POPUP_OPEN = 'UPDATE_RULE_POPUP_OPEN'
export const UPDATE_RULE_RESULT_INFO = 'UPDATE_RULE_RESULT_INFO'
export const UPDATE_RULE_RESULT_LOADING = 'UPDATE_RULE_RESULT_LOADING'
export const UPDATE_RULE_NOTIFICATION = 'UPDATE_RULE_NOTIFICATION'
export const UPDATE_SHOW_GUIDELINE = 'UPDATE_SHOW_GUIDELINE'
export const UPDATE_GUIDELINE_INFO = 'UPDATE_GUIDELINE_INFO'
export const RELOAD_RULE_RESULTS = 'RELOAD_RULE_RESULTS'
