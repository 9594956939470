import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CaseNavigation from './CaseNavigation';
import * as Dimens from '../../res/values/Dimens'
import SectionComponent from '../common/SectionComponent';
import { connect, useDispatch, useSelector } from 'react-redux';
import { store } from '../../store';
import {
    updateSelectedSectionId, updateSelectedSectionName, updateCaseView, updateCaseId, getBookingSheetDetails,
    isCreateCaseBl, updateCaseAmendmentBlStatus, getBookingSheetSectionListSuccess,
    setCreateCaseState, updateSectionGroupLoaderStatus, updateDisableNavigationStatus,getCancelCaseSection,
    updateCaseEditableMode, getNavigationSource, getSectionDetails, updateSignalRSectionLockStatus, 
    updateSignalRHubConnection, clearCaseDetails,sendSectionReleaseInfo, removeUserFromgroup,
    getDocumentDetailsByCaseId, rulePopUpOpen, updateRuleNotification, updateGuideLineStatus,
    getCaseRuleResults, reloadRuleResult
} from '../../actions/CaseDetailsAction';
import { useParams } from "react-router-dom";
import { getCreateCaseState, getGuidelineStatus, getReloadRuleResult } from '../../reducers/CaseDetailsReducer';
import * as constants from '../../constants/GlobalConstants';
import CaseDialogBox from './CaseDialogBox';
import LocalStorageProvider from '../../providers/LocalStorageProvider'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { SIGNAL_R_RECEIVE_MESSAGE_URL } from "../../constants/Locations";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserAuthWarningBox from './UserAuthWarningBox';
import DocumentAlertBox from './DocumentAlertBox';
import RuleDialogBox from './RuleDialogBox'
toast.configure();

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: Dimens.CONTENT_PADDING_LEFT,
        paddingTop: Dimens.CONTENT_PADDING_TOP,
    }
}))


function mapStateToProps(state) {
    return {
        sectionList: state.caseDetails.sections,
        loadedSectionId: state.caseDetails.loadedSectionId,
        sectionName: state.caseDetails.loadedSectionName,
        caseId: state.caseDetails.caseId,
        isCreateCase: state.caseDetails.isCreateCase,
        dashboardFilterData: state.dashboard.dashboardFiltersData,
        caseDetails: state.caseDetails,
        hubConnection: state.caseDetails.hubConnection,
        rulePopUpOpen: state.caseDetails.rulePopUpOpen,
        ruleNotification: state.caseDetails.ruleNotification,
    }
}



const initialState = {
    selectedSectionId: constants.CREATE_CASE_SECTION_ID,
    selectedSectionInfo: [],   
}

function Case(props) {
    let { caseId, sectionName } = useParams();
    const sectionList = props.sectionList
    const loadedSectionId = props.loadedSectionId
    const prevCaseId = props.caseDetails && props.caseDetails.prevCaseId ? props.caseDetails.prevCaseId : null;
    var selectedSectionId = loadedSectionId;
    var currentState = {};
    const dashboardFilterData = props.dashboardFilterData;
    var canSeeCancelCase = props.dashboardFilterData && props.dashboardFilterData.userActions.filter(element => element.userActionId == 3);

    if (!props.isCreateCase) {
        caseId = props.caseId
    }
    const isCreateCase = caseId == undefined && sectionName == undefined;
    var sectionUrl = '';
    var caseNavigation = {
        title: "Case Navigation",
        sections: sectionList
    }

    //if create case request
    if (isCreateCase) {
        currentState = initialState;
        selectedSectionId = initialState.selectedSectionId;
        caseNavigation.sections=[];        
    }
    else if (props.sectionName == constants.CASE_SUMMARY_PAGE_URL) {
        sectionUrl = constants.CASE_SUMMARY_PAGE_URL;
    }
    else if ('/' + sectionName == constants.CANCEL_CASE_PAGE_URL) {
        sectionUrl = props.sectionName != constants.SCHEDULING_PAGE_URL ? constants.CANCEL_CASE_PAGE_URL: constants.SCHEDULING_PAGE_URL;
    }
    else if (loadedSectionId == constants.CREATE_CASE_SECTION_ID) {
        sectionUrl = constants.PATIENT_INFORMATION_PAGE_URL
    }  
    else if (props.location && props.location.search && props.location.search.indexOf("?returnUrl=true") !== -1) {
        sectionUrl = constants.PATIENT_INFORMATION_PAGE_URL
    }   
    else {
        currentState = { selectedSectionId: loadedSectionId };
        if (sectionName) {
            sectionUrl = '/' + sectionName;
        }
    }

    const [state, setstate] = useState(currentState);
    const dispatch = useDispatch();
    const isCreateCaseState = useSelector(getCreateCaseState);
    const guideLineStatus = useSelector(getGuidelineStatus);
    const reloadGuideline = useSelector(getReloadRuleResult);
    const classes = useStyles()
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [guidelineValue, setGuidelineValue] = React.useState({ status: '', count: 0});
    const [connection, setConnection] = useState(props.hubConnection);

   
    if (!isCreateCase && sectionUrl != constants.CANCEL_CASE_PAGE_URL) {
        var sectionDetail = props.sectionList && props.sectionList.filter((item) => item.url == sectionUrl)[0];
        sectionDetail && props.sectionList && props.sectionList.map(element => {
            if (element.url == sectionDetail.url) {
                element.isSelected = true;
            } else {
                element.isSelected = false;
            }
        });
        //dispatch(getBookingSheetSectionListSuccess(props.sectionList));
    }

    useEffect(() => {
        dispatch(updateGuideLineStatus(guidelineValue))
    }, [guidelineValue.status, guidelineValue.count])

    useEffect(() => {
        if (!isCreateCase) {
            if (!connection) {
                const newConnection = new HubConnectionBuilder()
                    //.withUrl('https://localhost:5001/hubs/chat')
                    .withUrl(SIGNAL_R_RECEIVE_MESSAGE_URL, {
                        accessTokenFactory: () => {
                            return `${LocalStorageProvider.getAuthToken()}`
                        }
                    })
                    .withAutomaticReconnect(false)
                    .build();
                console.log("Signalr receive msg api url ", SIGNAL_R_RECEIVE_MESSAGE_URL);
                console.log("Signalr client setting connection");

                setConnection(newConnection);
                store.dispatch(updateSignalRHubConnection(newConnection));

            }
        }
               
       // release section here bcs it's work like page unload but unable to get store data currently
        return()=>{           
                 
          dispatch(sendSectionReleaseInfo());
          dispatch(removeUserFromgroup());  
          store.dispatch(clearCaseDetails());         
        }
    }, []);


    useEffect(() => {        
        if (connection && !connection._connectionStarted) {
            connection.start()
                .then(() => {
                    console.info('SignalR client start and Connected and ConnectionId= ', connection.connectionId);
                    addUserTogroup(caseId); //add user to group
                    //ReceiveMessage  on connection change or data change from api
                    connection.on('CurrentSectionLockStatus', response => {
                        // const loggedUserId = LocalStorageProvider.getLoginUserID();  //todo: To be handled by Punit
                        const currentSectionId = store.getState().caseDetails.loadedSectionId;
                        if (response && response.httpStatusCode === 200 && response.payload && response.payload.sectionLockBl == true) {
                            const tempMsg = "The Page is currently being edited by " + response.payload.sectionLockedByName;

                            //avoiding showing alert for whick user locking the section
                            if (/* loggedUserId != response.payload.sectionLockedBy && */
                                connection.connectionId != response.payload.connectionId &&
                                currentSectionId == response.payload.sectionId) {
                                updateSectionLockValueOnReceiveSignalRMessage(response.payload.sectionLockBl, response.payload.sectionLockedByName, false, false, false);
                                showToastPopup(tempMsg);

                            }
                        }
                        else if (response && response.payload && response.payload.sectionReleaseBl == true && currentSectionId == response.payload.sectionId) {
                            updateSectionLockValueOnReceiveSignalRMessage(false, null, false, response.payload.sectionReloadBl, response.payload.sectionReleaseBl);
                        }
                        else {
                            console.log("signalR api response is: ", JSON.stringify(response))
                        }
                    });

                    //Recieve message on rule status and count changes
                    connection.on('RuleResults_Updated', response => {
                        const guideLinePayload = {
                            status: "",
                            count: 0
                        }
                        if(response !== null) {
                            guideLinePayload.status = response.recommendationStatus
                            guideLinePayload.count = response.recommendationCount
                        }
                        setGuidelineValue(prevState => {
                            if (prevState.status !== guideLinePayload.status || prevState.count !== guideLinePayload.count) {
                                return guideLinePayload;
                            }
                            return prevState;
                        });
                    });
                    
                })
                .catch(
                    error => console.error('SignalR client Connection failed: ', error)
                );
        }       
    }, [connection]);


    //This effect will apply if url is changed/refreshed and then call relevent api's for section
    useEffect(() => {  
        if (sectionUrl == constants.CANCEL_CASE_PAGE_URL ) {
            if(sectionList && sectionList.length == 0){
                dispatch(getBookingSheetDetails(0, parseInt(caseId), sectionUrl)); //call sectionlist api if browser refreshed in case of cancel case
                dispatch(getCancelCaseSection(constants.CANCEL_CASE_SECTION_ID, caseId));
                props.history.push('/case/' + caseId + sectionUrl);
            }
        }        
        else {
            if (!isCreateCase) {              
                dispatch(isCreateCaseBl(false));
                dispatch(updateCaseView(true));
                dispatch(updateCaseId(parseInt(caseId)));             
                props.history.push('/case/' + caseId + sectionUrl);               
                if (!isCreateCaseState) {
                    if (sectionName != props.sectionName) {
                        dispatch(getBookingSheetDetails(0, parseInt(caseId), sectionUrl));         
                    }
                }               
            }
            else {               
                props.history.push('/case');
            }
        }
    }, [sectionUrl]);   
      
  

    const showToastPopup = (displayMessage) => toast.info(displayMessage, {
        toastId: "section_lock_toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
    });


    //Send message to signal R to lock the section
    const sendSectionLockInfo = async (userDetails, caseDetails) => {

        const caseObj = {
            CaseId: caseDetails.CaseId,
            SectionId: caseDetails.SectionId,
            CurrentUserDetails: userDetails
        };

        if (connection._connectionStarted) {
            try {
                await connection.invoke('UpdateSectionLockStatus', caseObj);
                console.log("update section lock status api called, UpdateSectionLockStatus");
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            console.log('No hubConnection to server yet, sendSectionLockInfo');

        }
    }    

    const addUserTogroup = async (caseId) => {


        if (connection && connection._connectionStarted && caseId != undefined && caseId != null) {
            try {
                const groupName = LocalStorageProvider.getTenantKey() + '_' + caseId;
                await connection.invoke('JoinToCaseGroup', "" + groupName);
                console.log("group added", groupName);

            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            console.log('No hubConnection to server yet, addUserTogroup');

        }
    }    

    const updateSectionLockValueOnReceiveSignalRMessage = (sectionLockBl, sectionLockedByName, isSectionEditable, isShowSectionDataRefreshIcon, sectionReleaseBl) => {
        const sectionLockedData = {
            sectionLockBl: sectionLockBl,
            sectionLockedByName: sectionLockedByName,
            isShowSectionDataRefreshIcon: isShowSectionDataRefreshIcon,
            sectionReleaseBl: sectionReleaseBl
        }
        store.dispatch(updateSignalRSectionLockStatus(sectionLockedData));
        //store.dispatch(updateCaseAmendmentBlStatus(false));
        console.log("SignalR Event: isSectionEditable ", isSectionEditable);
        store.dispatch(updateCaseEditableMode(isSectionEditable));       
    }

    const updateSectionStatus = (sectionId, status) => {
        var sections = caseNavigation.sections;
        sections && sections.forEach(element => {
            if (sectionId == element.id) {
                element.isSectionComplete = status;
            }
            if (sectionId == constants.CANCEL_CASE_SECTION_ID) {
                element.isSelected = false
            }
        });
        caseNavigation.sections = sections;
        setstate({
            selectedSectionId: sectionId,
        })
    }


    const askForConfirmation = (section, actionId = null) => {
        setDialogOpen(true);
        setstate({
            selectedSectionInfo: section
        })

    };

    const onCancel = () => {
        setDialogOpen(false);
    };

    const onConfirm = () => {       
        dispatch(sendSectionReleaseInfo());
        var section = state.selectedSectionInfo;
        navigateToSection(section, null);
        dispatch(updateCaseEditableMode(false));
        onCancel();        
    };

    const redirectToDashboard = () => {       
        props.history.push('/dashboard');
        window.location.reload(); //TODO: Need to handle it by redux-store data
    }
    const reloadPage = () => { 
        window.location.reload();
    }

    //To handle rule result upon notification confirmation
    const onRuleDialogConfirm = () => {
        const notificationPayload = {
            status: false,
            title: '',
            message: ''
        }
        dispatch(updateRuleNotification(notificationPayload))
        if(reloadGuideline) {
            dispatch(getCaseRuleResults(caseId))
            dispatch(reloadRuleResult(false))
        }
    }

    const openDialog = (
        <CaseDialogBox
            open={dialogOpen}
            dialogTitle="Reject Process"
            dialogDescription={constants.NAVIGATION_WARNING_MESSAGE}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    )
    const openDialog2 = (
        <UserAuthWarningBox
            open={!props.caseDetails.isUserAuthenticated}
            dialogTitle="Unauthorized!"
            dialogDescription={constants.AUTHORIZATION_ERROR_MESSAGE}
            onConfirm={redirectToDashboard}
        />
    )
    const documentDialog = (
        <DocumentAlertBox
            open={props.caseDetails.showAlertDialog}
            dialogTitle=""
            dialogDescription={constants.DOCUMENT_PAGE_ALERT}
            onConfirm={reloadPage}
        />
    )

    const ruleDialog = (
        <CaseDialogBox
            open={props.ruleNotification.status}
            dialogTitle={props.ruleNotification.title}
            dialogDescription={props.ruleNotification.message}
            onConfirm={onRuleDialogConfirm}
        />
    )

    const approveRejactDialog = (
        <CaseDialogBox
            open={props.ruleNotification.status}
            dialogTitle={props.ruleNotification.title}
            dialogDescription={props.ruleNotification.message}
            onConfirm={() => {
                const notificationPayload = {
                    status: false,
                    title: '',
                    message: ''
                }
                dispatch(updateRuleNotification(notificationPayload))
                dispatch(rulePopUpOpen(false))
            }}
        />
    )

    const handleCaseNavigation = (section, actionId = null) => {
        if (props.caseDetails.isCaseEditable) {
            document.body.style.overflow = "hidden";
            askForConfirmation(section, actionId = null);
            document.body.style.overflow = "auto";
        }
        else {
            navigateToSection(section, actionId);
        }
    }

    const navigateToSection = (section, actionId = null) => {
        store.dispatch(setCreateCaseState(false));
        store.dispatch(updateCaseAmendmentBlStatus(false));
        // if(section.id === loadedSectionId)
        // {
        //     store.dispatch(getSectionDetails(section.id, caseId));
        // }
        if (section.id !== loadedSectionId) {
            store.dispatch(updateDisableNavigationStatus(true));
            store.dispatch(updateSectionGroupLoaderStatus(true));
        }
        var navigationSections = caseNavigation;
        const sectionName = section.url;
        navigationSections.sections.forEach(element => {
            if (element.id == section.id) {
                element.isSelected = true
            }
            else {
                element.isSelected = false

            }
        })
        caseNavigation.sections = navigationSections;
        selectedSectionId = section.id;
        store.dispatch(updateSelectedSectionId(section.id));
        store.dispatch(updateSelectedSectionName(sectionName));

        setstate({
            selectedSectionId: section.id,
        });
        const pageUrl = '/case/' + caseId + section.url
        props.history.push(pageUrl);  
    }

    const handleCaseNavigationOnChange = (sectionId, actionId = null) => {
        store.dispatch(updateSectionGroupLoaderStatus(true));
        store.dispatch(setCreateCaseState(false));
        var navigationSections = caseNavigation;
        navigationSections.sections.forEach(element => {
            if (element.id == sectionId) {
                element.isSelected = true

            }
            else {
                element.isSelected = false

            }
        })
        caseNavigation.sections = navigationSections;
        setstate({
            selectedSectionId: sectionId,
        })
    }

    if (props.hubConnection) {
        addUserTogroup(caseId); //add user to group
    }

    return (

        <div className={classes.root}>
            {openDialog}
            {openDialog2}
            {documentDialog}
            {ruleDialog}
            <RuleDialogBox open={props.rulePopUpOpen} handleClose={() => dispatch(rulePopUpOpen(false))} />
            <Grid container>
                <Grid item md={3} lg={2}>
                    <CaseNavigation
                        caseId={caseId}
                        handleCaseNavigation={handleCaseNavigation}
                        navItems={caseNavigation}
                        sectionId={selectedSectionId}
                        canCancelCase={canSeeCancelCase && canSeeCancelCase[0].allowedActionBl}
                    />
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    <SectionComponent
                        key={selectedSectionId}
                        sectionId={selectedSectionId}
                        handleCaseNavigation={handleCaseNavigationOnChange}
                        updateSectionStatus={updateSectionStatus}
                        dashboardFilterData={dashboardFilterData}
                        sendSectionLockInfo={sendSectionLockInfo}
                        sectionUrl={sectionUrl}
                    />
                    <ToastContainer />
                </Grid>
            </Grid>
        </div>
    )
}

export default connect(mapStateToProps)(Case)