//import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@mui/lab";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
//import DateFnsUtils from "@date-io/date-fns";
import React, { useState } from 'react';
//import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDate } from '../../utils/fieldProperties/DateTime';
import {
  DISABLE_FUTURE_DATE_KEY,
  DISABLE_PAST_DATE_KEY} from '../../constants/GlobalConstants';

const useStyles = makeStyles(theme => ({
  root: { 
      width: '100%'
  }
}))


export default function KeyboardDatePicker(props) {
    var {
      fieldId,
      name,
      label,
      disabled,
      value,
      format,
      margin,
      id,
      variant,
      isRequired,
      onChange,
      helperText,
      error = false,
      maxDate = undefined,
      minDate = undefined,
      views = undefined,
      fieldSize = undefined,
      placeholder,
      readOnly = false,
      ...other
    } = props
    const classes = useStyles()
    const additionalProps= other.additionalProperties
    const placeHolderText = placeholder ? placeholder : additionalProps.Format
    
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value == (undefined || null) ? null : value}
          format={formatDate(other.additionalProperties.Format)}
          //mask="____/__/__"  
          margin={margin}
          id={fieldId}
          disabled = {disabled}
          name={name}
          label={label}
          variant={variant}
          onChange={onChange} 
          error={error}
          helperText={helperText}
          views={views}
          maxDate={additionalProps[DISABLE_FUTURE_DATE_KEY] ? new Date() : maxDate}        
          minDate={additionalProps[DISABLE_PAST_DATE_KEY] ? new Date() : minDate}      
          className={classes.root}
          //inputVariant="outlined"
          renderInput={props => {           
            return (
              <TextField
                {...props}
                inputProps={{
                  ...props.inputProps,
                  placeholder: placeHolderText,
                  readOnly: readOnly
                }}
                className={classes.root}
                size={fieldSize}
                error={error}
                helperText={helperText}
              />
            )
          }}
        />        
       </LocalizationProvider>
    )
}
