import {
    GET_DATAUPLOAD_ORG_TENANTS_SUCCESS,
    GET_DATAUPLOAD_ORG_TENANTS_FAIL,
    GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS,
    GET_ADMIN_DATAUPLOAD_TYPES_FAIL,
    SET_INITIAL_STAGE,
    UPDATE_ADMIN_FILE_UPLOAD_LOADER,
    UPLOAD_ADMIN_DATA_SUCCESS,
    UPLOAD_ADMIN_DATA_FAIL,
    TOGGLE_PANEL,
    SET_ERROR,
    GET_MAIN_RUN_DATA_SUCCESS,
    GET_MAIN_RUN_DATA_FAIL
} from '../constants/ActionTypes';

const initialState = {
    expandPanelOne: true,
    expandPanelTwo: false,
    expandPanelThree: false,
    isMandatoryDataForDryRunFilled:false,
    isMandatoryDataForMainRunFilled:false,
    orgTenants: [],
    adminDataUploadTypes: [],
    dryRunData: [],
    mainRunData: [],
    error: null,
    loading: false,
};

const ACTION_HANDLERS = {

    [SET_INITIAL_STAGE]: (state, action) => ({
        ...state,
        expandPanelOne: true,
        expandPanelTwo: false,
        expandPanelThree: false,
        isMandatoryDataForDryRunFilled:false,
        isMandatoryDataForMainRunFilled:false,
        dryRunData: [],
        mainRunData: [],
        error: null,
        loading: false,
    }),

    [SET_ERROR]: (state, action) => ({
        ...state,
        dryRunData: [],
        mainRunData: [],
        expandPanelOne: true,
        expandPanelTwo: false,
        expandPanelThree: false,
        isMandatoryDataForDryRunFilled:false,
        isMandatoryDataForMainRunFilled:false,
        error: action.payload,
        loading: false,
    }),

    [TOGGLE_PANEL]: (state, action) => ({
        ...state,
        expandPanelOne: action.payload == "panel1" ? true : false,
        expandPanelTwo: action.payload == "panel2" ? true : false,
        expandPanelThree: action.payload == "panel3" ? true : false,
        error: null,
        loading: false,
    }),

    [UPDATE_ADMIN_FILE_UPLOAD_LOADER]: (state, action) => {
        return {
            ...state,
            error: null,
            loading: action.payload
        };
    },

    [GET_DATAUPLOAD_ORG_TENANTS_SUCCESS]: (state, action) => {
        return {
            ...state,
            orgTenants: action.payload.selections,
            error: null,
            loading: false
        };
    },

    [GET_DATAUPLOAD_ORG_TENANTS_FAIL]: (state, action) => {
        return {
            ...state,
            orgTenants: [],
            error: action.payload,
            loading: false
        };
    },

    [GET_ADMIN_DATAUPLOAD_TYPES_SUCCESS]: (state, action) => {
        return {
            ...state,
            adminDataUploadTypes: action.payload.selections,
            error: null,
            loading: false
        };
    },

    [GET_ADMIN_DATAUPLOAD_TYPES_FAIL]: (state, action) => {
        return {
            ...state,
            adminDataUploadTypes: [],
            error: action.payload,
            loading: false
        };
    },

    [UPLOAD_ADMIN_DATA_SUCCESS]: (state, action) => {
        return {
            ...state,
            dryRunData: action.payload,
            expandPanelOne: false,
            expandPanelTwo: true,
            expandPanelThree: false,
            isMandatoryDataForDryRunFilled:true,
            isMandatoryDataForMainRunFilled:false,
            mainRunData: [],
            error: null,
            loading: false
        };
    },

    [UPLOAD_ADMIN_DATA_FAIL]: (state, action) => {
        return {
            ...state,
            dryRunData: [],
            expandPanelOne: true,
            expandPanelTwo: false,
            expandPanelThree: false,
            isMandatoryDataForDryRunFilled:false,
            isMandatoryDataForMainRunFilled:false,
            mainRunData: [],
            error: action.payload,
            loading: false
        };
    },

    [GET_MAIN_RUN_DATA_SUCCESS]: (state, action) => {
        return {
            ...state,           
            expandPanelOne: false,
            expandPanelTwo: false,
            expandPanelThree: true,
            isMandatoryDataForDryRunFilled:false,
            isMandatoryDataForMainRunFilled:true,
            mainRunData: action.payload,
            error: null,
            loading: false
        };
    },

    [GET_MAIN_RUN_DATA_FAIL]: (state, action) => {
        return {
            ...state,            
            expandPanelOne: false,
            expandPanelTwo: true,
            expandPanelThree: false,
            isMandatoryDataForDryRunFilled:true,
            isMandatoryDataForMainRunFilled:false,
            mainRunData: [],
            error: action.payload,
            loading: false
        };
    },

};


export default function adminUploadData(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export const getOrgTenantsData = state => state.dataUpload.orgTenants;
export const getAdminDataUploadTypesData = state => state.dataUpload.adminDataUploadTypes;
export const getDryRunData = state => state.dataUpload.dryRunData;
export const getMainRunData = state => state.dataUpload.mainRunData;
export const getLoadingStatus = state => state.dataUpload.loading;