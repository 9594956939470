import { getFieldById } from '../../GetFieldById';
import { store } from "../../../store";
import {
    POSITION_BED_TYPE_MAPPING_ID
} from '../../../constants/GlobalConstants';
import { updateFieldPermission } from '../../../actions/CaseDetailsAction';

const execute = (control, AddOnLoadONChangeFlag = null) => {
    const rule = control.additionalProperties.AlterTargetFieldPermissionsForBlankRows;
    const positionAndbedTypeTableInfo = getFieldById(POSITION_BED_TYPE_MAPPING_ID);
    if (rule && rule.SourceField && rule.ChildFields && rule.ChildFields.length) {
        var permissionDisabled = rule.IfTrueSetPermissionTypeId;
        var permissionEnabled = rule.IfFalseSetPermissionTypeId;
        const invalidRows = positionAndbedTypeTableInfo.childControls.filter((childControl, idx) => {
            const fieldObjOne = childControl.find((item) => item.mappingId.slice(0, -1) === rule.ChildFields[0]);
            const fieldObjTwo = childControl.find((item) => item.mappingId.slice(0, -1) === rule.ChildFields[1]);
            if (idx != 0 && fieldObjOne && !fieldObjOne.value && fieldObjTwo && !fieldObjTwo.value) {
                return true;
            }
            return false;
        });
        if (invalidRows.length) {
            store.dispatch(updateFieldPermission(rule.TargetField[0], permissionDisabled));
        } else {
            store.dispatch(updateFieldPermission(rule.TargetField[0], permissionEnabled));
        }
    }
}

const AlterTargetFieldPermissionsForBlankRowsInTargetField = {
    execute
}

export default AlterTargetFieldPermissionsForBlankRowsInTargetField;