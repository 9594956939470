export const ALL_CASES = "";
export const INCOMPLETE = 4;
export const COMPLETE = 3;
export const CLOSED = 1;
export const CANCELLED = 2;
export const PENDING_EPIC_UPDATE = 8;
export const ALERT = 9;


export const PENDING_REVIEW = 1;
export const APPROVED = 2;
export const REJECTED = 3;
